import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  FlatList,
  TouchableOpacity,
  TextInput,
  ActivityIndicator,
  Image
} from "react-native";
import { MaterialIcons } from "@expo/vector-icons";
import Glob from "src/globalConstants";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import NavBar from "src/components/navBar";
import Icon from "src/components/Icon";
import Rex from "src/globalState";
import TCLogoIcon from "src/components/TCLogoIcon";
import AlertModal from "src/components/AlertModal";

const { width } = Glob.get("dimensions");

export default function CommerceBillSomeone({ navigation, route }) {
  const { onInvoiceCreated = () => {} } = route.params || {};
  const [activeTab, setActiveTab] = useState("users"); // users or children
  const [tcIntegrationConnected, setTCIntegrationConnected] = useState(false);

  // Users state
  const [users, setUsers] = useState([]);
  const [userIDToUser, setUserIDToUser] = useState({});
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);

  // Children state
  const [children, setChildren] = useState([]);
  const [filteredChildren, setFilteredChildren] = useState([]);
  const [childSearchQuery, setChildSearchQuery] = useState("");
  const [childrenLoading, setChildrenLoading] = useState(true);
  const [childrenPayers, setChildrenPayers] = useState({});
  const [classrooms, setClassrooms] = useState({});

  // Alert state
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    Analytics.logEvent("view_commerceBillSomeone");
    fetchUsers();
  }, []);

  useEffect(() => {
    if (activeTab === "children" && (children || []).length < 1) {
      fetchChildren();
    }
  }, [activeTab]);

  const fetchUsers = async () => {
    try {
      if (Rex.getConfig()?.transparentClassroomIntegrationEnabled) {
        const response = await Database.tcCheckIntegrationStatus();
        const { status } = response || {};
        const connected =
          status === Glob.get("tcIntegrationStatuses").CONNECTED;
        setTCIntegrationConnected(connected);
        if (connected) {
          setActiveTab("children");
          await Database.fetchAllTCChildrenData().then((data) => {
            setChildrenPayers(data);
          });
        }
      }
      setLoading(true);
      const allUsers = await Database.fetchAllUsers();
      // Sort alphabetically by name
      const sortedUsers = allUsers.sort((a, b) =>
        `${a.firstName} ${a.lastName}`.localeCompare(
          `${b.firstName} ${b.lastName}`
        )
      );
      setUserIDToUser(
        sortedUsers.reduce((acc, user) => {
          acc[user.uid] = user;
          return acc;
        }, {})
      );
      setUsers(sortedUsers);
      setFilteredUsers(sortedUsers);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchChildren = async () => {
    try {
      setChildrenLoading(true);

      // Get all children from Transparent Classroom
      const childrenResponse = await Database.tcFetchChildren();
      const childrenData = childrenResponse.data || [];

      // Sort alphabetically
      const sortedChildren = childrenData.sort((a, b) =>
        `${a.first_name} ${a.last_name}`.localeCompare(
          `${b.first_name} ${b.last_name}`
        )
      );

      setChildren(sortedChildren);
      setFilteredChildren(sortedChildren);

      // Get classrooms for display purposes
      const classroomsResponse = await Database.tcFetchClassrooms();
      const classroomsMap = {};
      (classroomsResponse.data || []).forEach((classroom) => {
        classroomsMap[classroom.id] = classroom;
      });
      setClassrooms(classroomsMap);
    } catch (error) {
      console.error("Error fetching children data:", error);
    } finally {
      setChildrenLoading(false);
    }
  };

  const handleUserSearch = (text) => {
    setSearchQuery(text);

    if (text.trim() === "") {
      setFilteredUsers(users);
      return;
    }

    const filtered = users.filter((user) => {
      const fullName = `${user.firstName} ${user.lastName}`.toLowerCase();
      const email = user.email.toLowerCase();
      const query = text.toLowerCase();

      return fullName.includes(query) || email.includes(query);
    });

    setFilteredUsers(filtered);
  };

  const handleChildSearch = (text) => {
    setChildSearchQuery(text);

    if (text.trim() === "") {
      setFilteredChildren(children);
      return;
    }

    const filtered = children.filter((child) => {
      const fullName = `${child.first_name} ${child.last_name}`.toLowerCase();
      const query = text.toLowerCase();

      return fullName.includes(query);
    });

    setFilteredChildren(filtered);
  };

  const handleSelectUser = (user) => {
    Analytics.logEvent("touch_commerceBillSomeone_selectUser", {
      userID: user.uid
    });
    navigation.push("commerceCreateInvoice", {
      userID: user.uid,
      onInvoiceCreated: () => {
        onInvoiceCreated();
        // This callback will be executed when an invoice is created
        navigation.pop(2); // Go back to commerceBilling screen
      }
    });
  };

  const handleSelectChild = (child) => {
    Analytics.logEvent("touch_commerceBillSomeone_selectChild", {
      childID: child.id
    });
    const billingUserID = childrenPayers?.[child.id]?.billingUserID;

    // Check if the child has a billing parent assigned
    if (!billingUserID) {
      setAlert({
        title: "No Payment Profile",
        message:
          "This child does not have a parent payment profile assigned. Please set up a payment profile for this child in the 'All Children' screen before creating an invoice."
      });
      return;
    }

    // If there is a billing parent, proceed to invoice creation
    navigation.push("commerceCreateInvoice", {
      userID: billingUserID,
      child,
      onInvoiceCreated: () => {
        onInvoiceCreated();
        // This callback will be executed when an invoice is created
        navigation.pop(2); // Go back to commerceBilling screen
      }
    });
  };

  const renderUserItem = ({ item }) => (
    <TouchableOpacity
      style={styles.userCard}
      onPress={() => handleSelectUser(item)}
      activeOpacity={0.7}
    >
      <View
        style={[
          styles.avatarContainer,
          {
            backgroundColor:
              Rex.getConfig()?.colors?.background ||
              Rex.getConfig()?.colors?.button
          }
        ]}
      >
        <Text style={styles.avatarText}>
          {item.firstName[0]}
          {item.lastName[0]}
        </Text>
      </View>
      <View style={styles.userInfo}>
        <Text style={styles.userName}>
          {item.firstName} {item.lastName}
        </Text>
        <Text style={styles.userEmail}>{item.email}</Text>
      </View>
      <MaterialIcons name="chevron-right" size={24} color="#A0AEC0" />
    </TouchableOpacity>
  );

  const renderChildItem = ({ item: child }) => {
    const classroomNames = (child.classroom_ids || [])
      .map((id) => classrooms[id]?.name || "")
      .filter((name) => name !== "")
      .join(", ");

    // Check if the child has a valid profile photo
    const hasPhoto =
      !!child.profile_photo && child.profile_photo.includes("http");

    const billingUserID = childrenPayers?.[child.id]?.billingUserID;
    const billingUser = billingUserID ? userIDToUser?.[billingUserID] : null;
    const billingUserFullName = billingUser
      ? `${billingUser.firstName} ${billingUser.lastName}`
      : null;

    const hasBillingProfile = !!billingUserID && !!billingUserFullName;

    return (
      <TouchableOpacity
        style={styles.userCard}
        onPress={() => handleSelectChild(child)}
        activeOpacity={0.7}
      >
        {hasPhoto ? (
          <Image
            source={{ uri: child.profile_photo }}
            style={styles.childPhoto}
          />
        ) : (
          <View
            style={[styles.avatarContainer, { backgroundColor: "#3182CE" }]}
          >
            <Text style={styles.avatarText}>
              {child.first_name[0]}
              {child.last_name[0]}
            </Text>
          </View>
        )}
        <View style={styles.userInfo}>
          <Text style={styles.userName}>
            {child.first_name} {child.last_name}
          </Text>
          {classroomNames ? (
            <View style={styles.paymentProfileRow}>
              <Icon
                icon="01f1d136-1b73-4f8f-98d6-e6c65f8433e0"
                size={10}
                color="#6c757d"
                style={styles.paymentIcon}
              />
              <Text style={styles.userEmail}>{classroomNames}</Text>
            </View>
          ) : null}

          {/* Payment Profile Section */}
          <View style={styles.paymentProfileContainer}>
            {hasBillingProfile ? (
              <View style={styles.paymentProfileRow}>
                <Icon
                  icon="money"
                  color="#4CAF50"
                  size={11}
                  style={styles.paymentIcon}
                />
                <Text style={styles.paymentProfileText}>
                  {billingUserFullName}
                </Text>
              </View>
            ) : (
              <View style={styles.paymentProfileRow}>
                <MaterialIcons
                  name="warning"
                  size={11}
                  color="#F59E0B"
                  style={styles.paymentIcon}
                />
                <Text style={styles.noPaymentProfileText}>
                  No payment profile assigned
                </Text>
              </View>
            )}
          </View>
        </View>
        {hasBillingProfile && (
          <MaterialIcons name="chevron-right" size={24} color="#A0AEC0" />
        )}
      </TouchableOpacity>
    );
  };

  const renderTabBar = () => (
    <View style={styles.tabContainer}>
      <TouchableOpacity
        style={[
          styles.tabButton,
          activeTab === "children" && {
            backgroundColor: Rex.getConfig()?.colors?.button
          }
        ]}
        onPress={() => {
          Analytics.logEvent("touch_commerceBillSomeone_viewChildren");
          setActiveTab("children");
        }}
      >
        <Icon
          icon="people"
          color={activeTab === "children" ? "#fff" : "#4A5568"}
          size={18}
        />
        <Text
          style={[
            styles.tabText,
            activeTab === "children" && styles.activeTabText
          ]}
        >
          Child Accounts
        </Text>
      </TouchableOpacity>

      <TouchableOpacity
        style={[
          styles.tabButton,
          activeTab === "users" && {
            backgroundColor: Rex.getConfig()?.colors?.button
          }
        ]}
        onPress={() => {
          Analytics.logEvent("touch_commerceBillSomeone_viewUsers");
          setActiveTab("users");
        }}
      >
        <Icon
          icon="05475c18-1a66-4e08-9183-8b10b5b6de55"
          color={activeTab === "users" ? "#fff" : "#4A5568"}
          size={20}
        />
        <Text
          style={[
            styles.tabText,
            activeTab === "users" && styles.activeTabText
          ]}
        >
          App Members
        </Text>
      </TouchableOpacity>
    </View>
  );

  const renderUserContent = () => {
    if (loading) {
      return (
        <View style={styles.loadingContainer}>
          <ActivityIndicator
            size="large"
            color={Rex.getConfig()?.colors?.button}
          />
          <Text style={styles.loadingText}>Loading users...</Text>
        </View>
      );
    }

    return (
      <View style={styles.listContainer}>
        {filteredUsers.length > 0 ? (
          <FlatList
            data={filteredUsers}
            renderItem={renderUserItem}
            keyExtractor={(item) => item.uid}
            showsVerticalScrollIndicator={false}
            contentContainerStyle={styles.userList}
          />
        ) : (
          <View style={styles.emptyContainer}>
            <MaterialIcons name="search-off" size={48} color="#A0AEC0" />
            <Text style={styles.emptyText}>No users found</Text>
            <Text style={styles.emptySubtext}>Try a different search term</Text>
          </View>
        )}
      </View>
    );
  };

  const renderChildrenContent = () => {
    if (childrenLoading) {
      return (
        <View style={styles.loadingContainer}>
          <TCLogoIcon spinning size={40} />
          <Text style={styles.loadingText}>Loading children...</Text>
        </View>
      );
    }

    return (
      <View style={styles.listContainer}>
        {filteredChildren.length > 0 ? (
          <FlatList
            data={filteredChildren}
            renderItem={renderChildItem}
            keyExtractor={(item) => item.id.toString()}
            showsVerticalScrollIndicator={false}
            contentContainerStyle={styles.userList}
          />
        ) : (
          <View style={styles.emptyContainer}>
            <MaterialIcons name="search-off" size={48} color="#A0AEC0" />
            <Text style={styles.emptyText}>No children found</Text>
            <Text style={styles.emptySubtext}>Try a different search term</Text>
          </View>
        )}
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <NavBar navigation={navigation} text="Bill Someone" />

      <View style={{ width }}>
        <Text style={styles.headerText}>Who do you want to bill?</Text>

        {tcIntegrationConnected && renderTabBar()}

        <View style={styles.searchContainer}>
          <MaterialIcons
            name="search"
            size={22}
            color="#718096"
            style={styles.searchIcon}
          />
          <TextInput
            style={styles.searchInput}
            placeholder={
              activeTab === "users"
                ? "Search by name or email"
                : "Search by name"
            }
            value={activeTab === "users" ? searchQuery : childSearchQuery}
            onChangeText={
              activeTab === "users" ? handleUserSearch : handleChildSearch
            }
            placeholderTextColor="#A0AEC0"
            autoCapitalize="none"
            autoCorrect={false}
          />
          {(activeTab === "users" && searchQuery.length > 0) ||
          (activeTab === "children" && childSearchQuery.length > 0) ? (
            <TouchableOpacity
              onPress={() => {
                if (activeTab === "users") {
                  handleUserSearch("");
                } else {
                  handleChildSearch("");
                }
              }}
            >
              <MaterialIcons name="cancel" size={18} color="#A0AEC0" />
            </TouchableOpacity>
          ) : null}
        </View>
      </View>

      <ScrollView style={{ width }}>
        {activeTab === "users" ? renderUserContent() : renderChildrenContent()}
      </ScrollView>
      <AlertModal alert={alert} setAlert={setAlert} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "#F5F7FA"
  },
  tabContainer: {
    flexDirection: "row",
    marginHorizontal: 20,
    marginTop: 8,
    marginBottom: 8,
    borderRadius: 12,
    overflow: "hidden",
    backgroundColor: "#E2E8F0"
  },
  tabButton: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 12,
    backgroundColor: "transparent"
  },
  //   activeTabButton: {
  //     backgroundColor: "#2DD881"
  //   },
  tabText: {
    fontSize: 15,
    fontWeight: "500",
    color: "#4A5568",
    marginLeft: 6
  },
  activeTabText: {
    color: "#fff"
  },
  searchContainer: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "white",
    marginHorizontal: 20,
    marginTop: 8,
    marginBottom: 16,
    paddingHorizontal: 16,
    borderRadius: 12,
    height: 50,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.05,
    shadowRadius: 5,
    elevation: 2
  },
  searchIcon: {
    marginRight: 10
  },
  searchInput: {
    flex: 1,
    fontSize: 16,
    color: "#2D3748"
  },
  listContainer: {
    flex: 1,
    marginHorizontal: 20
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: "600",
    color: "#2D3748",
    marginBottom: 12
  },
  userList: {
    // width,
    paddingBottom: 20
  },
  userCard: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "white",
    padding: 16,
    borderRadius: 12,
    marginBottom: 10,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.05,
    shadowRadius: 3,
    elevation: 1
  },
  avatarContainer: {
    width: 44,
    height: 44,
    borderRadius: 22,
    // backgroundColor: "#2DD881",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 12
  },
  childPhoto: {
    width: 44,
    height: 44,
    borderRadius: 22,
    marginRight: 12,
    backgroundColor: "#f0f0f0" // Light background while loading
  },
  avatarText: {
    color: "white",
    fontSize: 16,
    fontWeight: "600"
  },
  userInfo: {
    flex: 1
  },
  userName: {
    fontSize: 16,
    fontWeight: "600",
    color: "#2D3748",
    marginBottom: 4
  },
  userEmail: {
    fontSize: 12,
    color: "#718096"
  },
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center"
  },
  loadingText: {
    marginTop: 12,
    fontSize: 16,
    color: "#718096"
  },
  emptyContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 50
  },
  emptyText: {
    fontSize: 18,
    fontWeight: "600",
    color: "#4A5568",
    marginTop: 16
  },
  emptySubtext: {
    fontSize: 14,
    color: "#718096",
    marginTop: 8
  },
  headerText: {
    fontSize: 18,
    fontWeight: "600",
    color: "#2D3748",
    marginTop: 20,
    marginHorizontal: 20
  },
  paymentProfileContainer: {
    marginTop: 4
  },
  paymentProfileRow: {
    flexDirection: "row",
    alignItems: "center"
  },
  paymentIcon: {
    marginRight: 4
  },
  paymentProfileText: {
    fontSize: 12,
    color: "#4CAF50"
  },
  noPaymentProfileText: {
    fontSize: 12,
    color: "#F59E0B"
  }
});
