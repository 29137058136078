import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  Text,
  ScrollView,
  KeyboardAvoidingView,
  Platform,
  Image,
  View,
  Switch,
  ActivityIndicator
} from "react-native";
import ConfettiCannon from "react-native-confetti-cannon";
import Glob from "src/globalConstants";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import NavBar from "src/components/navBar";
import AlertModal from "src/components/AlertModal";
import InputBox from "src/components/InputBox";
import Button from "src/components/Button";
import * as Font from "expo-font";
import Checkbox from "src/components/Checkbox";
import Util from "src/utility";
import Rex from "src/globalState";
import TCLogoIcon from "src/components/TCLogoIcon";

const EBGaramond = require("resources/fonts/EBGaramond/EBGaramond-VariableFont_wght.ttf");

const { width, height } = Glob.get("dimensions");

const INTEGRATION_TERMS_STATEMENT = `By checking this box and by integrating my (or my organization's) Transparent Classroom account with Onespot, I hereby:

(A) agree to be bound by Transparent Classroom's Terms of Service and Privacy Policy (collectively the "Agreement"),

(B) represent and warrant that I have the right, power, and authority to enter into this Agreement, and

(C) that I have the legal authority to bind my organization (e.g., school or school district). 

I further represent and warrant that I (on behalf of myself and my organization): 

(i) possess sufficient rights in and to all personal information, including all FERPA data, that is submitted, uploaded, or otherwise transferred to my Onespot and Transparent Classroom accounts, 

(ii) permit and authorize Transparent Classroom and Onespot to integrate and transfer all such data (including FERPA data) between my Onespot and Transparent Classroom accounts, and 

(iii) that I have obtained all required consents from students, teachers, schools, users and data owners, as may be necessary, to permit and authorize the foregoing. 

I agree not to integrate my Transparent Classroom account with Onespot if I do not accept and agree with the foregoing.`;

const STATUS = Glob.get("tcIntegrationStatuses");

export default function TransparentClassroomIntegration({ navigation, route }) {
  const { checkTCIntegrationStatus = () => {} } = route.params || {};
  const [alert, setAlert] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isCheckingConnection, setIsCheckingConnection] = useState(true);
  const [isConnecting, setIsConnecting] = useState(false);
  const [integrationStatus, setIntegrationStatus] = useState(
    STATUS.NOT_CONNECTED
  );
  const [tcSchoolID, setTCSchoolID] = useState(null);
  const [showConfetti, setShowConfetti] = useState(false);
  const [fontLoaded, setFontLoaded] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [listeningToTCAnnouncements, setListeningToTCAnnouncements] = useState(
    false
  );
  const [isUpdatingNotifications, setIsUpdatingNotifications] = useState(false);

  useEffect(() => {
    Analytics.logEvent("view_tcIntegration");
    Font.loadAsync({ EBGaramond }).then(() => setFontLoaded(true));

    Database.tcCheckIntegrationStatus().then((response) => {
      setIsCheckingConnection(false);
      const { status, schoolID } = response || {};
      setIntegrationStatus(
        status in STATUS ? STATUS[status] : STATUS.NOT_CONNECTED
      );
      setTCSchoolID(schoolID);
    });
    Database.subscribeToScheduledEvents((scheduledEvents) => {
      const listening =
        Object.values(scheduledEvents || {}).filter(
          (event) => event.type === Glob.get("tcAnnouncementListenerEventKey")
        ).length > 0;
      setListeningToTCAnnouncements(listening);
    });
    return () => {
      Database.unsubscribeFromScheduledEvents();
    };
  }, []);

  const canSubmit =
    !isConnecting && !!email && email.isEmail() && !!password && termsAccepted;

  const handleConnect = async () => {
    Analytics.logEvent("touch_tcIntegration_connect", { email });
    setIsConnecting(true);
    try {
      const response = await Database.tcAuthenticate(
        email,
        password,
        INTEGRATION_TERMS_STATEMENT,
        new Date().toISOString()
      );
      if (response.success) {
        Analytics.logEvent("success_tcIntegration_connect", { email });
        Database.tcCheckIntegrationStatus().then((statusResponse) => {
          const { status, schoolID } = statusResponse || {};
          setIntegrationStatus(
            status in STATUS ? STATUS[status] : STATUS.NOT_CONNECTED
          );
          setTCSchoolID(schoolID);
          if (status === STATUS.CONNECTED) setShowConfetti(true);
        });
        checkTCIntegrationStatus();
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      console.log("error:");
      console.log(error);
      Analytics.logEvent("error_tcIntegration_connect", {
        email,
        error: error.message
      });
      setAlert({
        title: error.message,
        message: "Please check your email and password and try again.",
        confirm: {
          text: "OK"
        }
      });
    }
    setIsConnecting(false);
  };

  const acceptInvitation = async () => {
    Analytics.logEvent("touch_tcIntegration_acceptInvitation");
    setIsCheckingConnection(true);
    try {
      await Database.tcAcceptOnespotAccountInvitation();
      const statusResponse = await Database.tcCheckIntegrationStatus();
      const { status, schoolID } = statusResponse || {};
      setIntegrationStatus(
        status in STATUS ? STATUS[status] : STATUS.NOT_CONNECTED
      );
      setTCSchoolID(schoolID);
      if (status === STATUS.CONNECTED) {
        try {
          const response = await Database.tcLinkUserAccounts();
          const { success, data } = response;
          if (success) {
            const { newlyLinkedTCUsers } = data || {};
            const numberOfNewlyLinkedUsers = (newlyLinkedTCUsers || []).length;
            setAlert({
              title: "Connection Successful 🎉",
              message: `Your Transparent Classroom integration is connected!${
                numberOfNewlyLinkedUsers > 0
                  ? ` And we were able to automatically link ${numberOfNewlyLinkedUsers} Transparent Classroom user account${
                      numberOfNewlyLinkedUsers > 1 ? "s" : ""
                    } with your Onespot member accounts.`
                  : ""
              }`
            });
            setShowConfetti(true);
            checkTCIntegrationStatus();
          } else {
            throw new Error("No users were linked.");
          }
        } catch (error) {
          console.log("error:");
          console.log(error);
          setAlert({
            title: "Connection Successful 🎉",
            message: "Your Transparent Classroom integration is connected!"
          });
          setShowConfetti(true);
          checkTCIntegrationStatus();
        }
      } else if (status === STATUS.NO_INVITATION_ACCEPTED) {
        setAlert({
          title: "Setup Not Completed",
          message:
            "You need to invite contact@onespotapps.com as an admin in Transparent Classroom to complete the setup."
        });
      } else {
        setAlert({
          title: "Not Connected",
          message:
            "Your Transparent Classroom integration is not connected. Please sign in with your TC credentials."
        });
      }
    } catch (error) {
      console.log("error   connection:", error);
      Analytics.logEvent("error_tcIntegration_acceptInvitation", {
        error: error.message
      });
      setAlert({
        title: "Connection Failed",
        message:
          "There was an error accepting the invitation. Please try again, or reach out to us for help: contact@onespotapps.com."
      });
    } finally {
      setIsCheckingConnection(false);
    }
  };

  if (isCheckingConnection) {
    return (
      <KeyboardAvoidingView
        style={styles.pageContent}
        behavior={Platform.OS === "ios" ? "padding" : "height"}
      >
        <NavBar navigation={navigation} text="TC Integration" />
        <View style={styles.loadingContainer}>
          <TCLogoIcon size={40} spinning />
          <Text style={styles.loadingText}>Accepting invitation...</Text>
        </View>
      </KeyboardAvoidingView>
    );
  }

  const renderContent = () => {
    if (integrationStatus === STATUS.CONNECTED) {
      return (
        <>
          <View style={styles.successContainer}>
            <View style={styles.successIconContainer}>
              <Text style={styles.successIcon}>✓</Text>
            </View>
            <Text style={styles.successText}>
              Your app is linked with Transparent Classroom
            </Text>
            <View style={styles.successSubtextContainer}>
              <Text style={styles.successSubtext}>
                You've unlocked all the benefits of the Transparent Classroom +
                Onespot bundle, including single sign-on, data syncing, and
                more! New functionality is continuously released.
              </Text>
            </View>
          </View>
          <View style={styles.successContainer}>
            <View style={styles.notificationPreference}>
              <View style={styles.notificationTextContainer}>
                <View style={styles.titleRow}>
                  <Text style={styles.notificationTitle}>
                    Automatic Notifications
                  </Text>
                  <View
                    style={[
                      styles.statusBadge,
                      listeningToTCAnnouncements
                        ? styles.enabledBadge
                        : styles.disabledBadge
                    ]}
                  >
                    <Text style={styles.statusText}>
                      {listeningToTCAnnouncements ? "Enabled" : "Disabled"}
                    </Text>
                  </View>
                </View>
                <Text style={styles.notificationDescription}>
                  {listeningToTCAnnouncements ? "Your" : "If enabled, your"} app
                  will automatically send a push notification to all members
                  whenever a school-wide announcement is posted in Transparent
                  Classroom.
                </Text>
              </View>
              <View style={styles.toggleContainer}>
                {isUpdatingNotifications ? (
                  <ActivityIndicator size="small" />
                ) : (
                  <Switch
                    value={listeningToTCAnnouncements}
                    onValueChange={async (value) => {
                      setIsUpdatingNotifications(true);
                      if (value) {
                        await Database.tcEnableAnnouncementListener();
                      } else {
                        await Database.deleteScheduledEvent(
                          Glob.get("tcAnnouncementListenerEventKey")
                        );
                      }
                      setIsUpdatingNotifications(false);
                    }}
                  />
                )}
              </View>
            </View>
          </View>
        </>
      );
    }
    if (integrationStatus === STATUS.NO_INVITATION_ACCEPTED) {
      return (
        <>
          <View style={styles.infoCard}>
            <Text style={styles.infoTitle}>One More Step</Text>
            <Text style={styles.infoText}>
              Authentication with Transparent Classroom was successful, but one
              more step is required.
            </Text>
            <Text style={styles.infoText}>
              To finish linking the two systems, log into Transparent Classroom
              and invite contact@onespotapps.com as an admin account.
            </Text>
          </View>

          <View style={styles.stepContainer}>
            <View
              style={[
                styles.stepBadge,
                { backgroundColor: Rex.getConfig()?.colors?.button || "#222" }
              ]}
            >
              <Text style={styles.stepNumber}>1</Text>
            </View>
            <View style={styles.stepContent}>
              <Text style={styles.stepTitle}>Read Instructions</Text>
              <Button
                text="Instructions"
                icon="3a1c6da5-3e54-4e2f-9ff4-35d8001f12e9"
                textStyle={styles.buttonText}
                style={styles.actionButton}
                onPress={() => {
                  navigation.push("webNav", {
                    title: "Invite Onespot",
                    url:
                      "https://www.loom.com/share/30af90f936844104b5e6d03c76bc33b9"
                  });
                }}
              />
            </View>
          </View>

          {tcSchoolID && (
            <View style={styles.stepContainer}>
              <View
                style={[
                  styles.stepBadge,
                  { backgroundColor: Rex.getConfig()?.colors?.button || "#222" }
                ]}
              >
                <Text style={styles.stepNumber}>2</Text>
              </View>
              <View style={styles.stepContent}>
                <Text style={styles.stepTitle}>Invite Onespot as an Admin</Text>
                <Button
                  text="Transparent Classroom"
                  icon="d24166f4-d993-4d3a-8692-ae839b70b6af"
                  textStyle={styles.buttonText}
                  style={styles.actionButton}
                  onPress={() => {
                    const url = tcSchoolID
                      ? `https://www.transparentclassroom.com/s/${tcSchoolID}/users/new?type=staff`
                      : "https://www.transparentclassroom.com/souls/sign_in";
                    Util.openURL(url);
                  }}
                />
              </View>
            </View>
          )}

          <View style={styles.stepContainer}>
            <View
              style={[
                styles.stepBadge,
                { backgroundColor: Rex.getConfig()?.colors?.button || "#222" }
              ]}
            >
              <Text style={styles.stepNumber}>3</Text>
            </View>
            <View style={styles.stepContent}>
              <Text style={styles.stepTitle}>Complete & Link Platforms</Text>
              <Button
                text="Enable Integration"
                icon="31d39f17-e398-4c66-8350-7b2292b5042f"
                textStyle={styles.buttonText}
                style={styles.actionButton}
                onPress={acceptInvitation}
              />
            </View>
          </View>
        </>
      );
    }
    return (
      <>
        <View style={styles.infoCard}>
          <Text style={styles.infoTitle}>Connect Your Account</Text>
          <Text style={styles.infoText}>
            Sign in to your Transparent Classroom account to automatically link
            it with Onespot and unlock all your integration benefits.
          </Text>
        </View>

        <View style={styles.formContainer}>
          <Text style={styles.infoHeader}>
            Transparent Classroom Credentials
          </Text>
          <InputBox
            placeholder="Email"
            icon="email"
            value={email}
            onChangeText={setEmail}
            keyboardType="email-address"
            autoCapitalize="none"
            returnKeyType="next"
            isInvalid={email && !email.isEmail()}
            isValid={email && email.isEmail()}
            style={styles.inputBox}
            textInputStyle={{ width: "80%" }}
          />
          <InputBox
            placeholder="Password"
            icon="combokeeper"
            value={password}
            onChangeText={setPassword}
            secure
            returnKeyType="done"
            style={styles.inputBox}
            textInputStyle={{ width: "80%" }}
          />
          <Button
            text={isConnecting ? "Connecting..." : "Connect"}
            onPress={handleConnect}
            disabled={!canSubmit}
            style={[styles.connectButton, { opacity: canSubmit ? 1 : 0.7 }]}
            textStyle={styles.connectButtonText}
          />

          <View style={styles.termsContainer}>
            <Text style={styles.infoHeader}>Data Sharing Agreement</Text>
            <Checkbox
              text={INTEGRATION_TERMS_STATEMENT}
              checked={termsAccepted}
              onChange={setTermsAccepted}
              style={styles.checkbox}
              textStyle={styles.termsText}
            />
          </View>
        </View>
      </>
    );
  };

  return (
    <KeyboardAvoidingView
      style={styles.pageContent}
      behavior={Platform.OS === "ios" ? "padding" : "height"}
    >
      <NavBar navigation={navigation} text="TC Integration" />
      <ScrollView
        style={styles.scrollView}
        contentContainerStyle={styles.container}
        scrollIndicatorInsets={{ right: 1 }}
        keyboardDismissMode="on-drag"
        keyboardShouldPersistTaps="handled"
      >
        <View style={styles.logoContainer}>
          <Image
            source={Glob.get("onespotTCLogoImage")}
            style={styles.logo}
            resizeMode="contain"
          />
          <Text
            style={[
              styles.tagline,
              { fontFamily: fontLoaded ? "EBGaramond" : undefined }
            ]}
          >
            A Whole School Solution
          </Text>
        </View>

        {renderContent()}
      </ScrollView>

      {showConfetti && (
        <ConfettiCannon
          count={200}
          origin={{ x: width / 2, y: height }}
          autoStart
          fadeOut
        />
      )}
      <AlertModal alert={alert} setAlert={setAlert} />
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  pageContent: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "#F8F9FD"
  },
  scrollView: {
    flex: 1,
    width
  },
  container: {
    paddingHorizontal: 24,
    paddingBottom: 40
  },
  logoContainer: {
    alignItems: "center",
    marginTop: 20,
    marginBottom: 20
  },
  logo: {
    width: width * 0.7,
    height: width * 0.35,
    alignSelf: "center"
  },
  tagline: {
    fontSize: 28,
    marginTop: 15,
    marginBottom: 10,
    textAlign: "center",
    color: "#333333"
  },
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center"
  },
  loadingText: {
    marginTop: 15,
    fontSize: 16,
    color: "#666"
  },
  infoCard: {
    backgroundColor: "white",
    borderRadius: 16,
    padding: 24,
    marginBottom: 24,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.05,
    shadowRadius: 15,
    elevation: 2
  },
  infoTitle: {
    fontSize: 20,
    fontWeight: "600",
    marginBottom: 16,
    color: "#333"
  },
  infoText: {
    fontSize: 16,
    lineHeight: 24,
    color: "#666",
    marginBottom: 12
  },
  infoHeader: {
    fontSize: 16,
    fontWeight: "600",
    marginBottom: 12,
    color: "#333"
  },
  formContainer: {
    backgroundColor: "white",
    borderRadius: 16,
    padding: 24,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.05,
    shadowRadius: 15,
    elevation: 2
  },
  inputBox: {
    marginBottom: 16
  },
  connectButton: {
    marginTop: 16,
    height: 56,
    borderRadius: 28
  },
  connectButtonText: {
    fontSize: 18,
    fontWeight: "600"
  },
  termsContainer: {
    marginTop: 24
  },
  termsText: {
    fontSize: 13,
    lineHeight: 20,
    color: "#666"
  },
  checkbox: {
    marginBottom: 20,
    alignItems: "flex-start"
  },
  stepContainer: {
    flexDirection: "row",
    backgroundColor: "white",
    borderRadius: 16,
    padding: 20,
    marginBottom: 16,
    alignItems: "flex-start",
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.05,
    shadowRadius: 15,
    elevation: 2
  },
  stepBadge: {
    width: 32,
    height: 32,
    borderRadius: 16,
    justifyContent: "center",
    alignItems: "center",
    marginRight: 16,
    marginTop: 4
  },
  stepNumber: {
    color: "white",
    fontSize: 16,
    fontWeight: "bold"
  },
  stepContent: {
    flex: 1
  },
  stepTitle: {
    fontSize: 18,
    fontWeight: "600",
    marginBottom: 12,
    color: "#333"
  },
  actionButton: {
    width: "100%",
    borderRadius: 28,
    height: 54
  },
  buttonText: {
    fontSize: 16,
    fontWeight: "600"
  },
  successContainer: {
    backgroundColor: "white",
    borderRadius: 16,
    padding: 30,
    alignItems: "center",
    marginVertical: 20,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.05,
    shadowRadius: 15,
    elevation: 2
  },
  successIconContainer: {
    backgroundColor: "#2DD881",
    width: 64,
    height: 64,
    borderRadius: 32,
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 20
  },
  successIcon: {
    color: "white",
    fontSize: 36,
    fontWeight: "bold"
  },
  successText: {
    fontSize: 20,
    fontWeight: "600",
    color: "#333",
    marginBottom: 10,
    textAlign: "center"
  },
  successSubtextContainer: {
    width: "100%",
    alignItems: "flex-start"
  },
  successSubtext: {
    fontSize: 14,
    color: "#666",
    textAlign: "left",
    lineHeight: 24
  },
  notificationPreference: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%"
  },
  notificationTextContainer: {
    flex: 1,
    paddingRight: 16
  },
  titleRow: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 6
  },
  notificationTitle: {
    fontSize: 17,
    fontWeight: "600",
    color: "#333",
    marginRight: 8
  },
  statusBadge: {
    paddingHorizontal: 8,
    paddingVertical: 2,
    borderRadius: 12,
    justifyContent: "center",
    alignItems: "center"
  },
  enabledBadge: {
    backgroundColor: "#2DD881"
  },
  disabledBadge: {
    backgroundColor: "#ADADAD"
  },
  statusText: {
    color: "white",
    fontSize: 12,
    fontWeight: "600"
  },
  notificationDescription: {
    fontSize: 14,
    color: "#666",
    lineHeight: 20
  },
  toggleContainer: {
    width: 50,
    alignItems: "center",
    justifyContent: "center"
  }
});
