import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  FlatList,
  TouchableOpacity,
  Platform,
  ActivityIndicator,
  ScrollView,
  Image
} from "react-native";
import {
  Octicons,
  Ionicons,
  MaterialIcons,
  Entypo,
  FontAwesome5
} from "@expo/vector-icons";
import { Stagger, useDisclose } from "native-base";
import LottieView from "src/components/Lottie";
import * as Haptics from "expo-haptics";
import Glob from "src/globalConstants";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import NavBar from "src/components/navBar";
import Rex from "src/globalState";
import Util from "src/utility";
import Button from "src/components/Button";
import TouchableLink from "src/components/dynamicContent/TouchableLink";
import SearchBar from "src/components/searchBar";
import AlertModal from "src/components/AlertModal";
import FloatingActionButton from "src/components/FloatingActionButton";
import Icon from "src/components/Icon";
import TCLogoIcon from "src/components/TCLogoIcon";

const MAILBOX_ANIMATION = require("resources/animations/mailbox.json");

const NEW_POST_MESSAGE_SUFFIX = " posted a new message";
const NEW_REPLY_MESSAGE_SUFFIX = " replied to your post";
const NEW_THREAD_REPLY_MESSAGE_SUFFIX = " replied to a thread you're in";

const { height, width, fullWidth } = Glob.get("dimensions");

const ICON_SIZE = Math.min(
  0.07 * width,
  Glob.deviceHasTabletDimensions() ? 42 : 26
);

const notificationIsProbablyAboutAFeed = (notification) => {
  const { title = "", portalID } = notification;
  return (
    !!portalID &&
    (title.endsWith(NEW_POST_MESSAGE_SUFFIX) ||
      title.endsWith(NEW_REPLY_MESSAGE_SUFFIX) ||
      title.endsWith(NEW_THREAD_REPLY_MESSAGE_SUFFIX))
  );
};

const notificationIsProbablyAGeneralNotification = (notification) => {
  return (
    !notification.isActivityFeed &&
    !notification.isTCActivity &&
    !notificationIsProbablyAboutAFeed(notification) &&
    !!notification.notificationDetails
  );
};

// Helper function to format TC activity text by replacing [child_id], [user_id], etc.
const formatTCActivityText = (
  text,
  allChildren = [],
  allUsers = {},
  allLessons = {}
) => {
  if (!text) return "";

  // Create lookup maps for faster access
  const childrenMap = {};
  allChildren.forEach((child) => {
    childrenMap[child.id] = `${child.first_name} ${child.last_name}`;
  });

  // Replace child tags with child names
  let formattedText = text.replace(/\[child_(\d+)\]/g, (match, childId) => {
    return childrenMap[childId] ? childrenMap[childId] : match;
  });

  // Replace user tags with user names (if we have user data)
  formattedText = formattedText.replace(/\[user_(\d+)\]/g, (match, userId) => {
    return allUsers[userId]
      ? `${allUsers[userId].first_name} ${allUsers[userId].last_name}`
      : match;
  });

  // Replace lesson tags with lesson name if available, else "a lesson"
  formattedText = formattedText.replace(
    /\[lesson_(\d+)\]/g,
    (match, lessonId) => {
      return allLessons[lessonId] ? allLessons[lessonId].name : "a lesson";
    }
  );

  return formattedText;
};

// Helper function to create a properly formatted preview of TC activity
const createTCActivityPreview = (
  activity,
  allChildren = [],
  allUsers = {},
  allLessons = {}
) => {
  if (!activity) return "New classroom activity";

  const formattedText = formatTCActivityText(
    activity.normalized_text,
    allChildren,
    allUsers,
    allLessons
  );

  // If there's a photo but no text
  if (activity.photo_url && (!formattedText || formattedText.trim() === "")) {
    return "Shared a classroom photo";
  }

  if (formattedText) return formattedText.truncate(72);

  return "New classroom activity";
};

const Notification = ({
  notification,
  navigation,
  markNotificationAsOpened,
  deleteNotification,
  isDeleting,
  onUpdatePortal,
  childrenData = [],
  allChildren = [],
  allUsers = {},
  allLessons = {}
}) => {
  let type = "notification";
  if (notification.chat && !notification.notificationDetails) type = "chat";
  if (notification.isActivityFeed) type = "activityFeed";
  if (notification.isTCActivity) type = "tcActivity";

  // Prepare a child avatar or TC icon for TC activities
  const renderTCActivityIcon = () => {
    if (!notification.isTCActivity) return null;

    // Try to find the child data
    const childData = childrenData.find(
      (c) => `${c.id}` === `${notification.childId}`
    );

    if (
      childData &&
      childData.profile_photo &&
      childData.profile_photo.includes("http")
    ) {
      return (
        <Image
          source={{ uri: childData.profile_photo }}
          style={{
            width: 28,
            height: 28,
            borderRadius: 14,
            marginRight: 4
          }}
        />
      );
    }

    // Fallback to TC Logo if no photo
    return <TCLogoIcon size={24} />;
  };

  // For TC activity, get properly formatted body text
  const getFormattedNotificationBody = () => {
    if (notification.isTCActivity) {
      // If it's a TC activity, we need to format the text properly
      if (
        notification.latestActivity &&
        notification.latestActivity.normalized_text
      ) {
        return createTCActivityPreview(
          notification.latestActivity,
          allChildren,
          allUsers,
          allLessons
        );
      }
      return createTCActivityPreview(
        { normalized_text: notification.body },
        allChildren,
        allUsers,
        allLessons
      );
    }

    // Default case for other notification types
    if (notification.media) {
      return "Someone posted a photo";
    }

    return (notification.body || "").truncate(
      notification.isActivityFeed || notification.isTCActivity ? 72 : 90
    );
  };

  return (
    <TouchableOpacity
      key={notification.key}
      style={styles.notificationContainer}
      activeOpacity={0.7}
      onPress={() => {
        if (notification.isActivityFeed && notification.portal) {
          const {
            txtName,
            key: navName,
            icon,
            restrictedToFeedSubscribers,
            allowedAccountTypes
          } = notification.portal;
          navigation.push("activityFeed", {
            txtName,
            navName,
            restrictedToFeedSubscribers,
            allowedAccountTypes,
            icon,
            portalType: "activityFeed",
            onUpdatePortal
          });
        } else if (notification.isTCActivity) {
          // Navigate to the TC Activity Feed for this specific child
          Analytics.logEvent("touch_notifications_viewChildActivity", {
            childID: notification.childId
          });
          navigation.push("tcActivityFeed", {
            childActivity: notification.childActivity,
            childName: notification.title
          });
        } else {
          markNotificationAsOpened(notification.key);
          navigation.push("notificationDetails", {
            notification
          });
        }
      }}
    >
      <TouchableOpacity
        style={styles.notificationLeftContainer}
        disabled={!isDeleting}
        onPress={() => (isDeleting ? deleteNotification(notification.key) : {})}
      >
        {isDeleting && (
          <MaterialIcons
            name="delete"
            size={24}
            color={Glob.get("dangerRed")}
            style={{ marginRight: 8 }}
          />
        )}
        {!isDeleting && !!notification.unopened && (
          <Octicons
            name="dot-fill"
            size={20}
            color={Rex.getConfig()?.colors?.button}
          />
        )}
        {!isDeleting && (
          <View style={{ marginLeft: 4, marginRight: 8 }}>
            {type === "notification" && (
              <Ionicons
                name={`notifications${notification.unopened ? "" : "-outline"}`}
                size={24}
                color={Glob.get("gray")}
              />
            )}
            {type === "chat" && (
              <Ionicons
                name={`chatbubble${notification.unopened ? "" : "-outline"}`}
                size={24}
                color={Glob.get("gray")}
              />
            )}
            {type === "activityFeed" && (
              <Icon
                icon={
                  notification?.portal?.icon ||
                  "8bbff17c-69ee-40b4-9774-5b4db7c1d211"
                }
                size={24}
                color={Rex.getConfig()?.colors?.text}
              />
            )}
            {type === "tcActivity" && renderTCActivityIcon()}
          </View>
        )}
      </TouchableOpacity>
      <View style={styles.notificationContentContainer}>
        <View style={styles.notificationTitleContainer}>
          <Text
            style={[
              styles.notificationTitle,
              notification.isActivityFeed || notification.isTCActivity
                ? { color: Rex.getConfig()?.colors?.text }
                : {}
            ]}
          >
            {notification?.title?.truncate(24) || "Message"}
          </Text>
          <Text style={styles.notificationDate}>
            {Util.friendlyDate(notification.date, { isLong: false })}
          </Text>
        </View>
        <Text
          style={[
            styles.notificationBody,
            notification.isActivityFeed || notification.isTCActivity
              ? { color: Rex.getConfig()?.colors?.text }
              : {}
          ]}
        >
          {notification.isActivityFeed && (
            <Text style={{ fontWeight: "normal" }}>Latest Message: </Text>
          )}
          {notification.isTCActivity && (
            <Text style={{ fontWeight: "normal" }}>Latest Activity: </Text>
          )}
          {getFormattedNotificationBody()}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

export default function Notifications({ navigation, route }) {
  const { adminPrivileges, onUpdatePortal } = route?.params || {};
  const [notifications, setNotifications] = useState(null);
  const [chatPermissions, setChatPermissions] = useState(false);
  // const [isSchool, setIsSchool] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [searchPhrase, setSearchPhrase] = useState(false);
  const [filterSelected, setFilterSelected] = useState("important"); // "all", "important", "notifications", "chatsAndFeeds"
  const [alert, setAlert] = useState(null);

  // TC Integration
  const [tcIntegrationConnected, setTCIntegrationConnected] = useState(false);
  const [children, setChildren] = useState([]);
  const [isLoadingTCData, setIsLoadingTCData] = useState(false);
  const [childActivities, setChildActivities] = useState({});
  const [allChildren, setAllChildren] = useState([]);
  const [allUsers, setAllUsers] = useState({});
  const [allLessons, setAllLessons] = useState({});

  const { isOpen, onToggle } = useDisclose();

  const allowedToManageUsers =
    !!adminPrivileges && adminPrivileges?.includes("ManageUsers");
  const allowedToSendNotifications =
    !!adminPrivileges && adminPrivileges?.includes("PushNotifications");
  const allowedToChat = allowedToManageUsers || !!chatPermissions;

  // Fetch supporting TC data - all children, users, and lessons
  const fetchSupportingTCData = async () => {
    try {
      // Fetch all children
      const childrenResponse = await Database.tcFetchAllChildrenSimple();
      if (childrenResponse?.success && childrenResponse?.data) {
        setAllChildren(childrenResponse.data);
      }

      // Fetch all users
      const usersResponse = await Database.tcFetchAllUsersSimple();
      if (usersResponse?.success && usersResponse?.data) {
        const usersMap = {};
        usersResponse.data.forEach((user) => {
          usersMap[user.id] = user;
        });
        setAllUsers(usersMap);
      }

      // Fetch classrooms to get lesson set IDs
      const classroomsResponse = await Database.tcFetchOwnClassrooms();
      if (classroomsResponse?.success && classroomsResponse?.data) {
        const lessonSetIDs = [];

        classroomsResponse.data.forEach((classroom) => {
          if (classroom.lesson_set_id) {
            lessonSetIDs.push(classroom.lesson_set_id);
          }
        });

        // Fetch lesson details
        if (lessonSetIDs.length > 0) {
          const lessonsResponse = await Database.tcFetchLessonSetDetails(
            lessonSetIDs
          );
          if (lessonsResponse?.success && lessonsResponse?.data) {
            setAllLessons(lessonsResponse.data);
          }
        }
      }
    } catch (error) {
      console.error("Error fetching supporting TC data:", error);
    }
  };

  // Create TC activity notifications
  const createTCActivityNotifications = () => {
    if (!tcIntegrationConnected || Object.keys(childActivities).length === 0) {
      return [];
    }

    return Object.keys(childActivities)
      .map((childId) => {
        const childData = children.find((c) => `${c.id}` === `${childId}`);
        if (
          !childData ||
          !childActivities[childId] ||
          childActivities[childId].length === 0
        ) {
          return null;
        }

        // Sort activities by date (newest first)
        const sortedActivities = [...childActivities[childId]].sort(
          (a, b) => new Date(b.date) - new Date(a.date)
        );

        const latestActivity = sortedActivities[0];
        const childName = `${childData.first_name} ${childData.last_name}`;

        // Format the activity text for display
        const formattedActivityText = createTCActivityPreview(
          latestActivity,
          allChildren,
          allUsers,
          allLessons
        );

        // Convert date format to ISO string if it's just a date string
        const activityDate = latestActivity.date;
        const isoDate = activityDate.includes("T")
          ? activityDate
          : `${activityDate}T05:00:00.000Z`;

        return {
          key: `tc-activity-${childId}`,
          title: childName,
          body: formattedActivityText,
          date: isoDate,
          isTCActivity: true,
          childId,
          childActivity: sortedActivities,
          latestActivity,
          searchableText: `${childName} Transparent Classroom Activity ${formattedActivityText}`
        };
      })
      .filter(Boolean); // Remove null entries
  };

  // Update notifications when TC activities change
  useEffect(() => {
    if (notifications && tcIntegrationConnected) {
      const tcActivityNotifications = createTCActivityNotifications();

      // Only update if we have TC notifications to add
      if (tcActivityNotifications.length > 0) {
        // Filter out any existing TC notifications before adding new ones
        const baseNotifications = notifications.filter((n) => !n.isTCActivity);

        // Add new TC notifications and sort again
        const updatedNotifications = [
          ...baseNotifications,
          ...tcActivityNotifications
        ].sort((a, b) => new Date(b.date) - new Date(a.date));

        setNotifications(updatedNotifications);
      }
    }
  }, [
    childActivities,
    tcIntegrationConnected,
    allChildren,
    allUsers,
    allLessons
  ]);

  // Fetch child activities if TC is connected and children are available
  useEffect(() => {
    if (tcIntegrationConnected && children.length > 0) {
      fetchChildActivities();
      fetchSupportingTCData();
    }
  }, [tcIntegrationConnected, children]);

  // Fetch all activity for a specific child
  const fetchChildActivities = async () => {
    setIsLoadingTCData(true);
    const activities = {};
    const promises = children.map(async (child) => {
      try {
        const response = await Database.tcFetchOwnChildActivity(child.id);
        if (response.success && response.data && response.data.length > 0) {
          activities[child.id] = response.data;
        }
      } catch (error) {
        console.error(`Error fetching activity for child ${child.id}:`, error);
      }
    });

    await Promise.all(promises);
    setChildActivities(activities);
    setIsLoadingTCData(false);
  };

  useEffect(() => {
    Analytics.logEvent("view_notifications");

    // Check TC integration status
    Database.fetchAllUserData().then((user) => {
      if (user?.tcIDs?.tcUserID) {
        Database.tcCheckIntegrationStatus().then((response) => {
          const { status } = response || {};
          const connected =
            status === Glob.get("tcIntegrationStatuses").CONNECTED;
          setTCIntegrationConnected(connected);
          if (connected) {
            // Fetch this parent's children
            setIsLoadingTCData(true);
            Database.tcFetchOwnChildren()
              .then((resp) => {
                setChildren(resp.data);
                setIsLoadingTCData(false);
              })
              .catch((error) => {
                console.error("Error fetching children:", error);
                setIsLoadingTCData(false);
              });
          }
        });
      }
    });

    // todo: maybe change how notifications for new messages are sent and do it similar to how they're sent for new 1-1 chats
    Database.fetchAllActivityFeeds({ sorted: true }).then(
      ({ activityFeeds = [] }) => {
        const feedsFormatted = activityFeeds
          // todo: handle empty activity feeds (with no mostRecentPost)
          .filter(
            (feed) => feed.userIsSubscribed && feed?.mostRecentPost?.timestamp
          )
          .map((feed) => ({
            key: feed?.activityFeedID,
            title: feed?.title,
            body: feed?.mostRecentPost?.message
              ? `"${feed.mostRecentPost.message}"`
              : null,
            date: feed?.mostRecentPost?.timestamp,
            portal: feed?.portalMetadata,
            isActivityFeed: true,
            media: feed?.mostRecentPost?.media || null,
            searchableText: `${feed.title} ${
              feed?.mostRecentPost?.message || feed?.mostRecentPost?.media
                ? "image photo picture"
                : ""
            }`
          }));

        Database.subscribeToUserNotifications((newNotifications) => {
          if (!newNotifications || newNotifications.length < 1)
            setIsDeleting(false);
          const notificationsFormatted = newNotifications.map((n) => ({
            ...n,
            searchableText: `${n.body} ${n.title}`
          }));

          // For now, we'll set base notifications here. TC Activity notifications
          // will be added in the separate useEffect when childActivities updates
          const baseNotifications = [
            ...feedsFormatted,
            ...notificationsFormatted
          ];

          const sortedNotifications = baseNotifications.sort(
            (a, b) => new Date(b.date) - new Date(a.date)
          );
          setNotifications(sortedNotifications);
          Database.markUserNotificationsAsOpened();
        });
      }
    );
    Database.fetchAllAccountTypeDetails().then((accountTypes) => {
      Database.listenUserType((accountTypeID) => {
        const { chatPermissions: accountTypeChatPermissions } =
          accountTypeID in accountTypes ? accountTypes[accountTypeID] : {};
        const everyChatPermissionIsFalse =
          typeof accountTypeChatPermissions === "object" &&
          "accountTypes" in accountTypeChatPermissions &&
          Object.values(accountTypeChatPermissions.accountTypes).every(
            (value) => !value
          );
        if (accountTypeChatPermissions && !everyChatPermissionIsFalse)
          setChatPermissions(accountTypeChatPermissions);
      });
    });

    // cleanup called when component is unmounting
    return () => {
      Database.unsubscribeFromUserNotifications();
    };
  }, []);

  // This just updates the state value to mark it as opened
  const markNotificationAsOpened = (notificationID) => {
    setNotifications((prevState) => {
      const notificationIndex = prevState.findIndex(
        (n) => n.key === notificationID
      );
      const newState = [...prevState];
      newState[notificationIndex].unopened = false;
      return newState;
    });
  };

  // Delete this notification
  const deleteNotification = (notificationID) => {
    Analytics.logEvent("touch_notifications_deleteNotification");
    Database.deleteUserNotification(notificationID);
  };

  // Delete ALL the users notifications
  const deleteAllNotifications = () => {
    Analytics.logEvent("touch_notifications_deleteAllNotifications");
    Database.deleteAllUserNotifications();
  };

  const onPressNewNotification = () => {
    Analytics.logEvent("touch_notifications_createNewPushNotification");
    navigation.push("sendPushNotification");
    if (isOpen) onToggle();
  };

  const onPressNewChat = () => {
    Analytics.logEvent("touch_notifications_createNewChat");
    navigation.push("selectUsersForChat", {
      // If they're allowed to manage all users, they can chat with everyone with no restrictions
      chatPermissions: allowedToManageUsers ? null : chatPermissions
    });
    if (isOpen) onToggle();
  };

  const onPressChatWithSeabirder = () => {
    Analytics.logEvent("touch_notifications_askForHelp");
    Util.startChatWithSuperAdmin(navigation, !!adminPrivileges);
    if (isOpen) onToggle();
  };

  const onPressFloatingActionButton = () => {
    Analytics.logEvent("touch_notifications_pressNewChatButton");
    onToggle();
  };

  const onPressFilter = (filter) => {
    if (Platform.OS !== "web") Haptics.selectionAsync();
    setFilterSelected(filter);
  };

  const FloatingActionButtonIcon = () => {
    if (isOpen) return <Entypo name="chevron-down" size={40} color="white" />;
    if (allowedToManageUsers || allowedToSendNotifications || allowedToChat)
      return <Ionicons name="create-outline" size={40} color="white" />;
    return <FontAwesome5 name="question-circle" size={40} color="white" />;
  };

  const renderFloatingActionButtonComponent = () => {
    return (
      <>
        <View
          style={{
            width: 1, // so it doesn't block user from tapping on messages
            position: Platform.OS === "web" ? "fixed" : "absolute",
            right: 30,
            bottom: 120,
            justifyContent: "center",
            alignItems: "flex-end"
          }}
        >
          <Stagger
            visible={isOpen}
            initial={{
              opacity: 0,
              scale: 0,
              translateY: 34
            }}
            animate={{
              translateY: 0,
              scale: 1,
              opacity: 1,
              transition: {
                type: "spring",
                mass: 0.6,
                stagger: {
                  offset: 30,
                  reverse: true
                }
              }
            }}
            exit={{
              translateY: 34,
              scale: 0.5,
              opacity: 0,
              transition: {
                duration: 100,
                stagger: {
                  offset: 30,
                  reverse: true
                }
              }
            }}
          >
            {allowedToSendNotifications ? (
              <Button
                text="New Notification"
                icon="send"
                onPress={onPressNewNotification}
              />
            ) : (
              <></>
            )}
            {allowedToChat ? (
              <Button text="New Chat" icon="edit" onPress={onPressNewChat} />
            ) : (
              <></>
            )}
            <Button
              text="Contact App Developers"
              icon="caa6399d-41d8-458f-8607-e60814f86c5d" // an engineer
              onPress={onPressChatWithSeabirder}
            />
          </Stagger>
        </View>

        <FloatingActionButton
          icon={<FloatingActionButtonIcon />}
          onPress={onPressFloatingActionButton}
        />
      </>
    );
  };

  if (notifications === null)
    return (
      <View style={styles.pageContent}>
        <NavBar navigation={navigation} text="Messages" />
        <View style={styles.noMessagesContainer}>
          <ActivityIndicator size="large" style={{ marginTop: 20 }} />
        </View>
      </View>
    );

  // Show loading indicator when fetching TC data if we already have notifications
  const renderTCLoadingIndicator = () => {
    if (isLoadingTCData && tcIntegrationConnected) {
      return (
        <View style={styles.tcLoadingContainer}>
          <TCLogoIcon size={14} spinning />
          <Text style={styles.tcLoadingText}>Loading child activity...</Text>
        </View>
      );
    }
    return null;
  };

  let RightButton = null;
  if (notifications?.length > 0)
    RightButton = (
      <TouchableOpacity
        style={{
          width: "100%",
          height: "100%",
          alignItems: "flex-end",
          justifyContent: "center",
          paddingRight: 10
        }}
        activeOpacity={0.6}
        onPress={() => {
          if (!isDeleting)
            Analytics.logEvent("touch_notifications_startDeleting");
          else Analytics.logEvent("touch_notifications_doneDeleting");
          setIsDeleting(!isDeleting);
        }}
      >
        {isDeleting ? (
          <Entypo name="check" size={ICON_SIZE} color="white" />
        ) : (
          <MaterialIcons name="delete" size={ICON_SIZE} color="white" />
        )}
      </TouchableOpacity>
    );

  if (notifications?.length < 1)
    return (
      <View style={styles.pageContent}>
        <NavBar
          navigation={navigation}
          text="Messages"
          RightButton={RightButton}
        />
        <View style={{ height: height / 2, width, alignItems: "center" }}>
          <LottieView
            autoPlay
            loop={false}
            style={{ height: "100%", width: "100%" }}
            source={MAILBOX_ANIMATION}
          />
        </View>
        <View style={styles.noMessagesContainer}>
          <Text style={styles.noMessagesTextLarge}>You're all caught up!</Text>
          <Text style={{ color: "gray" }}>
            You haven't received any messages yet.
          </Text>
        </View>
        {renderFloatingActionButtonComponent()}
      </View>
    );

  let notificationsFiltered = notifications;
  if (searchPhrase)
    notificationsFiltered = Util.searchItems(
      notificationsFiltered,
      searchPhrase,
      "searchableText"
    );
  if (isDeleting)
    notificationsFiltered = notificationsFiltered.filter(
      (n) => !n.isActivityFeed && !n.isTCActivity
    );

  switch (filterSelected) {
    case "important":
      notificationsFiltered = notificationsFiltered.filter(
        (n) => !notificationIsProbablyAboutAFeed(n)
      );
      break;
    case "notifications":
      notificationsFiltered = notificationsFiltered.filter(
        notificationIsProbablyAGeneralNotification
      );
      break;
    case "chatsAndFeeds":
      notificationsFiltered = notificationsFiltered.filter(
        (n) =>
          (n.chat || n.isActivityFeed || n.isTCActivity) &&
          !notificationIsProbablyAboutAFeed(n)
      );
      break;
    default:
      break;
  }

  return (
    <View style={styles.pageContent}>
      <NavBar
        navigation={navigation}
        text="Messages"
        RightButton={RightButton}
      />
      {isDeleting && (
        <View
          style={{
            width: "100%",
            paddingVertical: 20,
            borderBottomWidth: 1,
            borderBottomColor: "lightgray",
            paddingHorizontal: 15
          }}
        >
          <Text style={[styles.notificationBody, { textAlign: "center" }]}>
            Clean up your inbox! Touch the red icon to the left of any message
            to permanently delete it.
          </Text>
          <Button
            text="Done"
            icon="check"
            onPress={() => {
              Analytics.logEvent("touch_notifications_doneDeleting");
              setIsDeleting(false);
            }}
          />
          <TouchableLink
            type="button"
            text="💥 Clear all messages"
            textStyle={{
              color: Glob.get("dangerRed"),
              textAlign: "center",
              marginTop: 10
            }}
            onPress={() => {
              Analytics.logEvent(
                "touch_notifications_pressedDeleteAllMessages"
              );
              setAlert({
                title: "✋ Are you sure?",
                message: "This will permanently delete all your notifications.",
                cancel: { text: "Cancel" },
                confirm: {
                  text: "Permanently Delete All",
                  color: Glob.get("dangerRed"),
                  onPress: deleteAllNotifications
                }
              });
            }}
          />
        </View>
      )}
      <View style={{ width: "100%", alignItems: "center" }}>
        <ScrollView
          horizontal
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={styles.filterContainer}
        >
          <TouchableOpacity
            style={{
              ...styles.filterPill,
              backgroundColor:
                filterSelected === "all"
                  ? Rex.getConfig()?.colors?.button || "black"
                  : "#f0f0f0"
            }}
            activeOpacity={0.7}
            onPress={() => onPressFilter("all")}
          >
            <Text
              style={[
                styles.filterText,
                filterSelected === "all" && styles.filterTextActive
              ]}
            >
              Show All
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              ...styles.filterPill,
              backgroundColor:
                filterSelected === "important"
                  ? Rex.getConfig()?.colors?.button || "black"
                  : "#f0f0f0"
            }}
            activeOpacity={0.7}
            onPress={() => onPressFilter("important")}
          >
            <Text
              style={[
                styles.filterText,
                filterSelected === "important" && styles.filterTextActive
              ]}
            >
              Important
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              ...styles.filterPill,
              backgroundColor:
                filterSelected === "notifications"
                  ? Rex.getConfig()?.colors?.button || "black"
                  : "#f0f0f0"
            }}
            activeOpacity={0.7}
            onPress={() => onPressFilter("notifications")}
          >
            <Text
              style={[
                styles.filterText,
                filterSelected === "notifications" && styles.filterTextActive
              ]}
            >
              Notifications
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              ...styles.filterPill,
              backgroundColor:
                filterSelected === "chatsAndFeeds"
                  ? Rex.getConfig()?.colors?.button || "black"
                  : "#f0f0f0"
            }}
            activeOpacity={0.7}
            onPress={() => onPressFilter("chatsAndFeeds")}
          >
            <Text
              style={[
                styles.filterText,
                filterSelected === "chatsAndFeeds" && styles.filterTextActive
              ]}
            >
              Chats & Feeds
            </Text>
          </TouchableOpacity>
        </ScrollView>
      </View>
      <SearchBar placeholder="Search" onChangeText={setSearchPhrase} />
      {renderTCLoadingIndicator()}
      <FlatList
        contentContainerStyle={{
          paddingBottom: 40,
          width: fullWidth,
          alignItems: "center"
        }}
        scrollIndicatorInsets={{ right: 1 }}
        data={notificationsFiltered}
        renderItem={({ item }) => (
          <Notification
            notification={item}
            navigation={navigation}
            markNotificationAsOpened={markNotificationAsOpened}
            deleteNotification={deleteNotification}
            isDeleting={isDeleting}
            onUpdatePortal={onUpdatePortal}
            childrenData={children}
            allChildren={allChildren}
            allUsers={allUsers}
            allLessons={allLessons}
          />
        )}
        keyExtractor={(item) => item.key}
      />
      {renderFloatingActionButtonComponent()}
      <AlertModal alert={alert} setAlert={setAlert} />
    </View>
  );
}

const styles = StyleSheet.create({
  pageContent: {
    alignItems: "center",
    backgroundColor: "white",
    height
  },
  formDescription: {
    marginVertical: 20
  },
  noMessagesContainer: {
    flex: 1,
    alignItems: "center",
    paddingHorizontal: 20
  },
  noMessagesTextLarge: {
    fontSize: 30,
    marginVertical: 20,
    color: "gray"
  },
  notificationContainer: {
    flexDirection: "row",
    width
  },
  notificationLeftContainer: {
    justifyContent: "flex-end",
    alignItems: "center",
    flexDirection: "row",
    flex: 2
  },
  notificationContentContainer: {
    borderColor: "lightgray",
    borderBottomWidth: 1,
    paddingVertical: 10,
    flex: 12,
    paddingRight: 5
  },
  notificationTitleContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginRight: 10
  },
  notificationTitle: {
    fontSize: 20
  },
  notificationDate: {
    fontSize: 12,
    color: "gray"
  },
  notificationBody: {
    fontSize: 15
  },
  filterContainer: {
    paddingHorizontal: 15,
    paddingTop: 20,
    paddingBottom: 10,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  filterPill: {
    paddingHorizontal: 10,
    justifyContent: "center",
    height: 24,
    borderRadius: 20,
    backgroundColor: "#f0f0f0",
    marginRight: 6
  },
  filterText: {
    fontSize: 12,
    color: "#666"
  },
  filterTextActive: {
    color: "white",
    fontWeight: "bold"
  },
  tcLoadingContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: 8,
    borderRadius: 4,
    marginTop: 4
  },
  tcLoadingText: {
    marginLeft: 8,
    fontSize: 12,
    color: "#666"
  }
});
