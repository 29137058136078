import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  KeyboardAvoidingView,
  Platform,
  TouchableOpacity,
  ActivityIndicator,
  TextInput,
  Image,
  Modal
} from "react-native";
import Glob from "src/globalConstants";
import Rex from "src/globalState";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import NavBar from "src/components/navBar";
import DatePicker from "src/components/DatePicker";
import { MaterialIcons, FontAwesome5 } from "@expo/vector-icons";
import Toast from "react-native-toast-message";
import AlertModal from "src/components/AlertModal";

const { width } = Glob.get("dimensions");

// // Unused function to darken a hex color
// const darkenColor = (color, percent = 20) => {
//   // Default color if none is provided
//   if (!color) return "#2D3748";

//   // Remove the # if it exists
//   const cleanColor = color.replace("#", "");

//   // Convert to RGB
//   let r = parseInt(cleanColor.substring(0, 2), 16);
//   let g = parseInt(cleanColor.substring(2, 4), 16);
//   let b = parseInt(cleanColor.substring(4, 6), 16);

//   // Darken by percentage
//   r = Math.floor((r * (100 - percent)) / 100);
//   g = Math.floor((g * (100 - percent)) / 100);
//   b = Math.floor((b * (100 - percent)) / 100);

//   // Convert back to hex
//   return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
// };

// First, add a function to calculate the number of billing cycles
const calculateBillingCycles = (startDate, endDate, interval) => {
  // If no end date, return null (ongoing subscription)
  if (!endDate) return null;

  // Use current date if startDate is null (immediate)
  const effectiveStartDate = startDate || new Date();

  // Calculate the difference based on interval type
  switch (interval) {
    case "day":
      // Days between dates (add 1 for inclusive)
      return (
        Math.ceil((endDate - effectiveStartDate) / (1000 * 60 * 60 * 24)) + 1
      );

    case "week":
      // Weeks between dates (add 1 for inclusive)
      return (
        Math.ceil((endDate - effectiveStartDate) / (1000 * 60 * 60 * 24 * 7)) +
        1
      );

    case "month": {
      // Calculate months between dates
      const startYear = effectiveStartDate.getFullYear();
      const startMonth = effectiveStartDate.getMonth();
      const endYear = endDate.getFullYear();
      const endMonth = endDate.getMonth();

      let months = (endYear - startYear) * 12 + (endMonth - startMonth);

      // Adjust for partial months based on day of month
      if (endDate.getDate() >= effectiveStartDate.getDate()) {
        months += 1; // Include current month if end date is on or after start date day
      }

      return Math.max(1, months); // Ensure at least 1 billing cycle
    }

    case "year": {
      // Calculate years between dates
      let years = endDate.getFullYear() - effectiveStartDate.getFullYear();

      // Adjust for partial years
      if (
        endDate.getMonth() > effectiveStartDate.getMonth() ||
        (endDate.getMonth() === effectiveStartDate.getMonth() &&
          endDate.getDate() >= effectiveStartDate.getDate())
      ) {
        years += 1; // Include current year if end date is on or after start date month/day
      }

      return Math.max(1, years); // Ensure at least 1 billing cycle
    }

    default:
      return null;
  }
};

export default function CommerceCreateInvoice({ route, navigation }) {
  const { userID, child } = route.params;
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [invoiceItems, setInvoiceItems] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showAddItemModal, setShowAddItemModal] = useState(false);
  const [products, setProducts] = useState([]);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [hasMixedItemTypes, setHasMixedItemTypes] = useState(false);
  const [alert, setAlert] = useState(null);
  const [collectionMethod, setCollectionMethod] = useState(null);
  const [achOnly, setAchOnly] = useState(false);
  const [hasDefaultPaymentMethod, setHasDefaultPaymentMethod] = useState(false);
  const [dueDate, setDueDate] = useState(() => {
    // Default to 30 days from today
    const date = new Date();
    date.setDate(date.getDate() + 30);
    return date;
  });

  // Subscription settings
  const [subscriptionStartDate, setSubscriptionStartDate] = useState(
    new Date()
  );
  const [subscriptionEndDate, setSubscriptionEndDate] = useState(null); // null means ongoing
  const [showSubscriptionSettings, setShowSubscriptionSettings] = useState(
    false
  );

  // Add state for discounts
  const [showDiscountSelector, setShowDiscountSelector] = useState(false);
  const [availableDiscounts, setAvailableDiscounts] = useState([]);
  const [selectedDiscounts, setSelectedDiscounts] = useState([]);
  const [isLoadingDiscounts, setIsLoadingDiscounts] = useState(false);

  // Add a state variable to detect mixed subscription intervals
  const [
    hasMixedSubscriptionIntervals,
    setHasMixedSubscriptionIntervals
  ] = useState(false);

  // Get the organization's primary color and create a darker version for contrast
  const primaryColor = Rex.getConfig()?.colors?.navbar || "#6C63FF";

  useEffect(() => {
    Analytics.logEvent("view_commerceCreateInvoice");
    fetchUser();
    fetchProducts();
    fetchDiscounts();
  }, []);

  useEffect(() => {
    const hasSubscriptionItems = invoiceItems.some(
      (item) => item.type === "product" && item.subscriptionInterval
    );
    const hasOneTimeItems = invoiceItems.some(
      (item) =>
        item.type === "custom" ||
        (item.type === "product" && !item.subscriptionInterval)
    );

    setHasMixedItemTypes(hasSubscriptionItems && hasOneTimeItems);

    // Check for mixed subscription intervals
    if (hasSubscriptionItems) {
      const intervals = new Set();
      invoiceItems.forEach((item) => {
        if (item.type === "product" && item.subscriptionInterval) {
          intervals.add(item.subscriptionInterval);
        }
      });

      const hasMixedIntervals = intervals.size > 1;
      setHasMixedSubscriptionIntervals(hasMixedIntervals);

      // Only show subscription settings if there aren't mixed intervals
      setShowSubscriptionSettings(hasSubscriptionItems && !hasMixedIntervals);
    } else {
      setHasMixedSubscriptionIntervals(false);
    }
  }, [invoiceItems]);

  const fetchUser = async () => {
    try {
      const stripeCustomer = await Database.stripeConnectedAccountFetchCustomer(
        {
          userID,
          createCustomerIfDoesntExist: true
        }
      );

      const userData = await Database.fetchUserByID(userID);
      setUser(userData);

      // Check if customer has a default payment method
      const hasDefault = !!stripeCustomer?.invoice_settings
        ?.default_payment_method;
      setHasDefaultPaymentMethod(hasDefault);

      // Set appropriate default collection method
      setCollectionMethod(hasDefault ? "charge_automatically" : "send_invoice");
    } catch (error) {
      console.error("Error fetching user:", error);
      // Default to send_invoice if error
      setCollectionMethod("send_invoice");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchProducts = async () => {
    setLoadingProducts(true);
    try {
      const stripeProducts = await Database.fetchStripeConnectedAccountProducts();
      if (stripeProducts?.data && Array.isArray(stripeProducts.data)) {
        const productsWithMetadata = stripeProducts.data.map((product) => ({
          ...product,
          customQuestions: product.metadata?.customQuestions
            ? JSON.parse(product.metadata.customQuestions)
            : [],
          adjustableQuantity: product.metadata?.adjustableQuantity === "true"
        }));
        setProducts(productsWithMetadata);
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setLoadingProducts(false);
    }
  };

  // Add this function to fetch available discounts
  const fetchDiscounts = async () => {
    setIsLoadingDiscounts(true);
    try {
      const response = await Database.stripeConnectedAccountFetchCoupons({
        active: true
      });

      // Check if we have a valid response with coupons data
      if (response.success && response.coupons) {
        const couponsData = response.coupons.data || [];
        setAvailableDiscounts(couponsData);

        if (couponsData.length === 0) {
          Toast.show({
            type: "info",
            text1: "No Active Discounts",
            text2:
              "You can create discounts in the Discount Management section."
          });
        }
      } else {
        console.error("Error in discount response structure:", response);
        setAvailableDiscounts([]);
        Toast.show({
          type: "info",
          text1: "No Discounts Available",
          text2:
            response.message ||
            "You can create discounts in the Discount Management section."
        });
      }
    } catch (error) {
      console.error("Exception while fetching discounts:", error);
      setAvailableDiscounts([]);
      Toast.show({
        type: "error",
        text1: "Error",
        text2: error.message || "Failed to load discounts. Please try again."
      });
    } finally {
      setIsLoadingDiscounts(false);
    }
  };

  // Helper function to render product content based on loading state and product availability
  const renderProductContent = () => {
    if (loadingProducts) {
      return (
        <ActivityIndicator
          size="large"
          color={primaryColor}
          style={styles.productsLoading}
        />
      );
    }

    if (products.length > 0) {
      return (
        <ScrollView style={styles.productList}>
          {products.map((product) => (
            <TouchableOpacity
              key={product.id}
              style={styles.productOption}
              onPress={() => handleAddProductItem(product)}
            >
              {product.images?.[0] ? (
                <Image
                  source={{ uri: product.images[0] }}
                  style={styles.productOptionImage}
                />
              ) : (
                <View
                  style={[
                    styles.productOptionImagePlaceholder,
                    { backgroundColor: primaryColor }
                  ]}
                >
                  <FontAwesome5 name="shopping-bag" size={16} color="white" />
                </View>
              )}
              <View style={styles.productOptionDetails}>
                <Text style={styles.productOptionName}>{product.name}</Text>
                <Text style={styles.productOptionPrice}>
                  ${product.default_price?.unit_amount / 100 || 0}
                </Text>
              </View>
            </TouchableOpacity>
          ))}
        </ScrollView>
      );
    }

    // Default case - no products
    return (
      <View style={styles.noProductsContainer}>
        <Text style={styles.noProductsText}>
          No products found. You can still add custom items.
        </Text>
      </View>
    );
  };

  const handleUpdateCustomItem = (index, field, value) => {
    const newItems = [...invoiceItems];
    newItems[index] = { ...newItems[index], [field]: value };
    setInvoiceItems(newItems);
  };

  const handleUpdateProductQuantity = (index, quantity) => {
    const newItems = [...invoiceItems];
    newItems[index] = {
      ...newItems[index],
      quantity: Math.max(1, parseInt(quantity, 10) || 1)
    };
    setInvoiceItems(newItems);
  };

  const handleAddItem = () => {
    setShowAddItemModal(true);
  };

  const handleAddCustomItem = () => {
    setInvoiceItems([
      ...invoiceItems,
      { type: "custom", amount: "", description: "" }
    ]);
    setShowAddItemModal(false);
  };

  const handleAddProductItem = (product) => {
    setInvoiceItems([
      ...invoiceItems,
      {
        type: "product",
        productId: product.id,
        priceId: product.default_price?.id,
        name: product.name,
        description: product.description || "",
        amount: product.default_price?.unit_amount / 100, // Convert from cents
        quantity: 1,
        image: product.images?.[0] || null,
        adjustableQuantity: product.adjustableQuantity || false,
        subscriptionInterval: product.default_price?.recurring?.interval || null
      }
    ]);
    setShowAddItemModal(false);
  };

  const handleRemoveItem = (index) => {
    const newItems = invoiceItems.filter((_, i) => i !== index);
    setInvoiceItems(newItems);
  };

  const calculateTotal = () => {
    return invoiceItems
      .reduce((sum, item) => {
        let amount = 0;
        if (item.type === "custom") {
          amount = parseFloat(item.amount) || 0;
        } else if (item.type === "product") {
          amount =
            (parseFloat(item.amount) || 0) * (parseInt(item.quantity, 10) || 1);
        }
        return sum + amount;
      }, 0)
      .toFixed(2);
  };

  // Calculate the total after applying all selected discounts
  const calculateDiscountedTotal = () => {
    const originalTotal = parseFloat(calculateTotal());

    if (selectedDiscounts.length === 0) {
      return originalTotal.toFixed(2);
    }

    let discountedTotal = originalTotal;

    // Apply each discount sequentially
    selectedDiscounts.forEach((discount) => {
      if (discount.percent_off) {
        // Apply percentage discount
        discountedTotal *= 1 - discount.percent_off / 100;
      } else if (discount.amount_off) {
        // Apply fixed amount discount (amount_off is in cents, convert to dollars)
        discountedTotal = Math.max(
          0,
          discountedTotal - discount.amount_off / 100
        );
      }
    });

    // Ensure we never return a negative total
    return Math.max(0, discountedTotal).toFixed(2);
  };

  // Check if any discounts are applied
  const hasDiscounts = () => {
    return selectedDiscounts.length > 0;
  };

  const handleAddDiscount = (discount) => {
    // Check if the discount is already selected
    if (selectedDiscounts.some((d) => d.id === discount.id)) {
      // If it is, remove it
      setSelectedDiscounts(
        selectedDiscounts.filter((d) => d.id !== discount.id)
      );
    } else {
      // Otherwise, add it
      setSelectedDiscounts([...selectedDiscounts, discount]);
      // Close the modal immediately after adding the discount
      setShowDiscountSelector(false);
    }
  };

  const renderDiscountSelector = () => {
    return (
      <Modal
        visible={showDiscountSelector}
        animationType="slide"
        transparent
        onRequestClose={() => setShowDiscountSelector(false)}
      >
        <View style={styles.modalContainer}>
          <View style={styles.modalOverlay}>
            <TouchableOpacity
              style={{ flex: 1 }}
              activeOpacity={1}
              onPress={() => setShowDiscountSelector(false)}
            />
          </View>
          <View style={styles.modalContent}>
            <View style={styles.modalHandle} />
            <View style={styles.modalHeader}>
              <Text style={styles.modalTitle}>Select Discounts</Text>
              <TouchableOpacity
                onPress={() => setShowDiscountSelector(false)}
                hitSlop={{ top: 15, right: 15, bottom: 15, left: 15 }}
                style={styles.closeButton}
              >
                <MaterialIcons name="close" size={24} color="#4A5568" />
              </TouchableOpacity>
            </View>

            <ScrollView
              style={styles.modalBody}
              showsVerticalScrollIndicator={false}
              contentContainerStyle={styles.modalBodyContent}
            >
              {renderDiscountContent()}
            </ScrollView>

            <View style={styles.modalFooter}></View>
          </View>
        </View>
      </Modal>
    );
  };

  // Helper function to render discount content based on loading state and data
  const renderDiscountContent = () => {
    if (isLoadingDiscounts) {
      return (
        <ActivityIndicator
          size="large"
          color={primaryColor}
          style={{ marginTop: 20 }}
        />
      );
    }

    if (availableDiscounts.length === 0) {
      return (
        <View style={styles.emptyDiscountsContainer}>
          <MaterialIcons
            name="loyalty"
            size={48}
            color="#CBD5E0"
            style={styles.emptyStateIcon}
          />
          <Text style={styles.emptyDiscountsText}>No discounts available</Text>
          <TouchableOpacity
            style={styles.createDiscountButton}
            onPress={() => {
              setShowDiscountSelector(false);
              navigation.navigate("commerceDiscounts");
            }}
          >
            <MaterialIcons name="add" size={20} color="white" />
            <Text style={styles.createDiscountButtonText}>
              Create Discounts
            </Text>
          </TouchableOpacity>
        </View>
      );
    }

    return (
      <View style={styles.discountsContentContainer}>
        {/* Create New Discount button at the top */}
        <TouchableOpacity
          style={styles.createNewDiscountButton}
          onPress={() => {
            setShowDiscountSelector(false);
            navigation.navigate("commerceDiscounts");
          }}
        >
          <MaterialIcons name="add" size={20} color={primaryColor} />
          <Text
            style={[
              styles.createNewDiscountButtonText,
              { color: primaryColor }
            ]}
          >
            Create New Discount
          </Text>
        </TouchableOpacity>

        {/* Discount options list */}
        <View style={styles.discountsList}>
          {availableDiscounts.map((discount) => {
            const isSelected = selectedDiscounts.some(
              (d) => d.id === discount.id
            );

            return (
              <TouchableOpacity
                key={discount.id}
                style={[
                  styles.discountOption,
                  isSelected && styles.selectedDiscountOption
                ]}
                onPress={() => handleAddDiscount(discount)}
              >
                <View style={styles.discountOptionContent}>
                  <View>
                    <Text style={styles.discountName}>
                      {discount.name || "Unnamed Discount"}
                    </Text>
                    <Text style={styles.discountValue}>
                      {discount.percent_off
                        ? `${discount.percent_off}% off`
                        : `$${(discount.amount_off / 100).toFixed(2)} off`}
                    </Text>
                  </View>
                  {isSelected && (
                    <MaterialIcons
                      name="check-circle"
                      size={24}
                      color={primaryColor}
                    />
                  )}
                </View>
              </TouchableOpacity>
            );
          })}
        </View>
      </View>
    );
  };

  const handleCreateInvoice = async () => {
    // Validate items
    if (invoiceItems.length === 0) {
      Toast.show({
        type: "error",
        text1: "No Items",
        text2: "Please add at least one item to the invoice"
      });
      return;
    }

    const hasInvalidCustomItems = invoiceItems
      .filter((item) => item.type === "custom")
      .some((item) => !item.amount || !item.description);

    if (hasInvalidCustomItems) {
      Toast.show({
        type: "error",
        text1: "Invalid Items",
        text2: "Please complete all custom item fields"
      });
      return;
    }

    // If we have mixed types, show a confirmation with AlertModal
    if (hasMixedItemTypes) {
      setAlert({
        title: "Mixed Item Types",
        message: hasDiscounts()
          ? `Just so you know, your invoice contains both subscription and one-time items. This will automatically create 2 invoices that will be processed as separate transactions. ${
              selectedDiscounts.length > 0
                ? `Discounts have been applied: Original $${calculateTotal()} → Final $${calculateDiscountedTotal()}`
                : ""
            }`
          : "Just so you know, your invoice contains both subscription and one-time items. This will automatically create 2 invoices that will be processed as separate transactions.",
        confirm: {
          text: `Bill ${user?.firstName ||
            "Customer"} $${calculateDiscountedTotal()}`,
          onPress: () => processInvoiceItems()
        },
        cancel: {
          text: "Cancel",
          color: "#A0AEC0"
        },
        disableOutsideTouch: false
      });
      return;
    }

    // Otherwise, proceed normally
    processInvoiceItems();
  };

  const processInvoiceItems = async () => {
    setIsSubmitting(true);
    try {
      // Separate subscription items from one-time items
      const subscriptionItems = invoiceItems.filter(
        (item) => item.type === "product" && item.subscriptionInterval
      );

      const oneTimeItems = invoiceItems.filter(
        (item) =>
          item.type === "custom" ||
          (item.type === "product" && !item.subscriptionInterval)
      );

      let subscriptionResult = null;
      let invoiceResult = null;

      // Format selected discounts for Stripe
      const formattedDiscounts = selectedDiscounts.map((discount) => ({
        coupon: discount.id
      }));

      // Normalize dates to get accurate day difference (ignore time)
      const calculateDaysDifference = (date1, date2) => {
        // Clone dates and set to midnight
        const d1 = new Date(
          date1.getFullYear(),
          date1.getMonth(),
          date1.getDate()
        );
        const d2 = new Date(
          date2.getFullYear(),
          date2.getMonth(),
          date2.getDate()
        );
        // Get difference in days
        return Math.floor((d2 - d1) / (1000 * 60 * 60 * 24));
      };

      // Process subscription items if we have any
      if (subscriptionItems.length > 0) {
        const formattedSubscriptionItems = subscriptionItems.map((item) => ({
          type: "product",
          priceId: item.priceId,
          quantity: parseInt(item.quantity, 10) || 1
        }));

        // Prepare subscription options
        const subscriptionOptions = {
          collection_method: collectionMethod,
          ach_only: achOnly,
          days_until_due:
            collectionMethod === "send_invoice"
              ? calculateDaysDifference(new Date(), dueDate)
              : undefined,
          discounts:
            formattedDiscounts.length > 0 ? formattedDiscounts : undefined
        };

        // Automatically set the billing cycle day to match the start date's day of month if a start date is specified
        if (subscriptionStartDate !== null) {
          const startDateDay = subscriptionStartDate.getDate();
          subscriptionOptions.billing_cycle_anchor_day = startDateDay;
        }

        // Add trial period if start date is in the future
        if (
          subscriptionStartDate !== null &&
          subscriptionStartDate > new Date()
        ) {
          // Calculate trial days - the number of days until the start date
          const trialDays = calculateDaysDifference(
            new Date(),
            subscriptionStartDate
          );
          if (trialDays > 0) {
            subscriptionOptions.trial_period_days = trialDays;
          }
        }

        // Add subscription end date if specified
        if (subscriptionEndDate !== null) {
          // Convert to Unix timestamp (seconds since epoch)
          subscriptionOptions.cancel_at = Math.floor(
            subscriptionEndDate.getTime() / 1000
          );
        }

        const result = await Database.stripeConnectedAccountCreateSubscription(
          userID,
          formattedSubscriptionItems,
          subscriptionOptions
        );

        // Check if we have multiple subscriptions in the response
        if (
          result.success &&
          result.subscriptions &&
          result.subscriptions.length > 0
        ) {
          subscriptionResult = result;
        } else if (result.success && result.subscription) {
          // For backward compatibility
          subscriptionResult = result;
        } else {
          Toast.show({
            type: "error",
            text1: "Error",
            text2: result.message || "Failed to create subscription"
          });
          setIsSubmitting(false);
          return;
        }
      }

      // Process one-time items if we have any
      if (oneTimeItems.length > 0) {
        const formattedOneTimeItems = oneTimeItems.map((item) => {
          if (item.type === "custom") {
            return {
              type: "custom",
              amount: parseFloat(item.amount) * 100, // Convert to cents
              description: item.description
            };
          }
          return {
            type: "product",
            priceId: item.priceId,
            quantity: parseInt(item.quantity, 10) || 1
          };
        });

        invoiceResult = await Database.stripeConnectedAccountCreateInvoice(
          userID,
          formattedOneTimeItems,
          [], // productItems parameter (empty array)
          {
            collection_method: collectionMethod,
            ach_only: achOnly,
            discounts: formattedDiscounts,
            ...(collectionMethod === "send_invoice" && {
              days_until_due: calculateDaysDifference(new Date(), dueDate)
            })
          }
        );
      }

      // Call the callback if it exists
      if (route.params?.onInvoiceCreated) {
        route.params.onInvoiceCreated();
      }

      // Show success message
      if (subscriptionResult && invoiceResult) {
        Toast.show({
          type: "success",
          text1: "Success!",
          text2: "Created both subscription and invoice"
        });
      } else if (subscriptionResult) {
        Toast.show({
          type: "success",
          text1: "Success!",
          text2: "Subscription created"
        });
      } else if (invoiceResult) {
        Toast.show({
          type: "success",
          text1: "Success!",
          text2: "Invoice created"
        });
      }

      navigation.goBack();
    } catch (error) {
      console.error("Error processing items:", error);
      Toast.show({
        type: "error",
        text1: "Error",
        text2: error.message || "Failed to process items"
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderInvoiceItem = (item, index) => {
    if (item.type === "custom") {
      return (
        <View key={index} style={styles.invoiceItemCompact}>
          <View style={styles.itemHeaderCompact}>
            <View style={styles.itemTitleRow}>
              <FontAwesome5
                name="file-invoice-dollar"
                size={14}
                color={primaryColor}
              />
              <Text style={styles.itemTitleCompact}>Custom Item</Text>
            </View>
            <TouchableOpacity
              hitSlop={{ top: 10, right: 10, bottom: 10, left: 10 }}
              onPress={() => handleRemoveItem(index)}
            >
              <MaterialIcons name="delete-outline" size={20} color="#E53E3E" />
            </TouchableOpacity>
          </View>

          <View style={styles.customItemContent}>
            <View style={styles.customItemRow}>
              <View style={styles.amountInputContainerCompact}>
                <Text style={styles.inputLabelCompact}>Amount</Text>
                <View style={styles.amountInputWrapperCompact}>
                  <Text style={styles.currencySymbolCompact}>$</Text>
                  <TextInput
                    style={styles.amountInputCompact}
                    placeholder="0.00"
                    keyboardType="decimal-pad"
                    value={item.amount.toString()}
                    onChangeText={(text) =>
                      handleUpdateCustomItem(index, "amount", text)
                    }
                  />
                </View>
              </View>
              <View style={styles.descriptionInputContainerCompact}>
                <Text style={styles.inputLabelCompact}>Description</Text>
                <TextInput
                  style={styles.descriptionInputCompact}
                  placeholder="Enter description"
                  value={item.description}
                  onChangeText={(text) =>
                    handleUpdateCustomItem(index, "description", text)
                  }
                />
              </View>
            </View>
          </View>
        </View>
      );
    }

    // Product item
    return (
      <View key={index} style={styles.invoiceItemCompact}>
        <View style={styles.itemHeaderCompact}>
          <View style={styles.itemTitleRow}>
            <FontAwesome5 name="shopping-bag" size={14} color={primaryColor} />
            <Text style={styles.itemTitleCompact}>
              {item.subscriptionInterval ? "Subscription" : "Product"}
            </Text>
            {item.subscriptionInterval && (
              <View
                style={[
                  styles.subscriptionBadge,
                  { backgroundColor: primaryColor }
                ]}
              >
                <Text style={styles.subscriptionBadgeText}>
                  {item.subscriptionInterval === "day"
                    ? "Daily"
                    : `${item.subscriptionInterval.charAt(0).toUpperCase() +
                        item.subscriptionInterval.slice(1)}ly`}
                </Text>
              </View>
            )}
          </View>
          <TouchableOpacity
            hitSlop={{ top: 10, right: 10, bottom: 10, left: 10 }}
            onPress={() => handleRemoveItem(index)}
          >
            <MaterialIcons name="delete-outline" size={20} color="#E53E3E" />
          </TouchableOpacity>
        </View>

        <View style={styles.productItemContainerCompact}>
          {item.image ? (
            <Image
              source={{ uri: item.image }}
              style={styles.productImageCompact}
            />
          ) : (
            <View
              style={[
                styles.productImagePlaceholder,
                { backgroundColor: `${primaryColor}40` }
              ]}
            >
              <FontAwesome5
                name={item.subscriptionInterval ? "sync" : "box"}
                size={14}
                color={primaryColor}
              />
            </View>
          )}

          <View style={styles.productDetailsCompact}>
            <Text style={styles.productNameCompact} numberOfLines={1}>
              {item.name}
            </Text>
            <Text style={styles.productPriceCompact}>
              ${parseFloat(item.amount).toFixed(2)}
            </Text>
          </View>

          <View style={styles.quantityControlsCompact}>
            <TouchableOpacity
              style={styles.quantityButtonCompact}
              onPress={() =>
                handleUpdateProductQuantity(
                  index,
                  (parseInt(item.quantity, 10) || 1) - 1
                )
              }
            >
              <MaterialIcons name="remove" size={16} color={primaryColor} />
            </TouchableOpacity>

            <TextInput
              style={styles.quantityInputCompact}
              keyboardType="number-pad"
              value={item.quantity?.toString() || "1"}
              onChangeText={(value) =>
                handleUpdateProductQuantity(index, value)
              }
            />

            <TouchableOpacity
              style={styles.quantityButtonCompact}
              onPress={() =>
                handleUpdateProductQuantity(
                  index,
                  (parseInt(item.quantity, 10) || 1) + 1
                )
              }
            >
              <MaterialIcons name="add" size={16} color={primaryColor} />
            </TouchableOpacity>
          </View>
        </View>

        <View style={styles.itemTotalCompact}>
          <Text style={styles.itemTotalLabelCompact}>Total:</Text>
          <Text style={styles.itemTotalAmountCompact}>
            $
            {(
              parseFloat(item.amount) * (parseInt(item.quantity, 10) || 1)
            ).toFixed(2)}
          </Text>
        </View>
      </View>
    );
  };

  const renderSubscriptionSettings = () => {
    // Only show if there are subscription items AND there aren't mixed intervals
    const hasSubscriptionItems = invoiceItems.some(
      (item) => item.type === "product" && item.subscriptionInterval
    );

    if (!hasSubscriptionItems || hasMixedSubscriptionIntervals) return null;

    return (
      <View style={styles.section}>
        <View style={styles.sectionHeader}>
          <Text style={styles.sectionHeaderText}>Subscription Settings</Text>
          <TouchableOpacity
            onPress={() =>
              setShowSubscriptionSettings(!showSubscriptionSettings)
            }
            style={{ padding: 5 }}
          >
            <MaterialIcons
              name={showSubscriptionSettings ? "expand-less" : "expand-more"}
              size={24}
              color="rgba(0, 0, 0, 0.6)"
            />
          </TouchableOpacity>
        </View>

        {showSubscriptionSettings && (
          <View style={styles.sectionContent}>
            <Text style={{ marginBottom: 16, color: "#4A5568" }}>
              Configure when recurring subscription billing should start, end,
              and on which day of the billing cycle payments should occur.
            </Text>

            {/* Subscription limitations warning */}
            {(() => {
              // Check if we have mixed interval types
              const intervals = new Set();
              invoiceItems.forEach((item) => {
                if (item.type === "product" && item.subscriptionInterval) {
                  intervals.add(item.subscriptionInterval);
                }
              });

              if (intervals.size > 1) {
                return (
                  <View
                    style={{
                      padding: 12,
                      backgroundColor: "#FFF5F5",
                      borderRadius: 8,
                      marginBottom: 16,
                      borderWidth: 1,
                      borderColor: "#FED7D7"
                    }}
                  >
                    <Text
                      style={{
                        color: "#C53030",
                        fontWeight: "600",
                        marginBottom: 4
                      }}
                    >
                      Multiple Billing Intervals Not Allowed
                    </Text>
                    <Text style={{ color: "#742A2A" }}>
                      Your invoice includes subscription products with different
                      billing intervals (e.g. both monthly and weekly items).
                      Please remove all but one interval type before submitting.
                      The invoice cannot be created with mixed intervals.
                    </Text>
                  </View>
                );
              }

              return null;
            })()}

            {/* Billing schedule information */}
            {subscriptionStartDate !== null && (
              <View
                style={{
                  padding: 12,
                  backgroundColor: "#FEFCBF",
                  borderRadius: 8,
                  marginBottom: 16,
                  borderWidth: 1,
                  borderColor: "#FAF089"
                }}
              >
                <Text
                  style={{
                    color: "#975A16",
                    fontWeight: "600",
                    marginBottom: 4
                  }}
                >
                  Billing Schedule Information
                </Text>
                <Text style={{ color: "#744210" }}>
                  Billing will begin on the selected start date and continue on
                  the same day of each period thereafter. If that day doesn't
                  exist in a future month (e.g., 31st in February), billing will
                  occur on the last day of that month.
                </Text>
              </View>
            )}

            {/* Start Date */}
            <View style={styles.subscriptionSettingItem}>
              <View style={styles.subscriptionSettingIcon}>
                <MaterialIcons
                  name="calendar-today"
                  size={24}
                  color="#8B5CF6"
                />
              </View>
              <View style={styles.subscriptionSettingContent}>
                <Text style={styles.subscriptionSettingLabel}>Start Date</Text>
                <Text
                  style={{ fontSize: 12, color: "#718096", marginBottom: 8 }}
                >
                  Determines when billing begins and which day of each period
                  payments will occur.
                </Text>
                <DatePicker
                  selectedDate={subscriptionStartDate}
                  onDateChange={setSubscriptionStartDate}
                  validRange={{
                    startDate: new Date()
                  }}
                />
                <TouchableOpacity
                  onPress={() => setSubscriptionStartDate(null)}
                  style={{ marginTop: 8 }}
                >
                  <Text style={{ color: "#8B5CF6" }}>
                    {subscriptionStartDate === null ? "✓ " : ""}
                    Immediate (default)
                  </Text>
                </TouchableOpacity>
              </View>
            </View>

            {/* End Date */}
            <View style={styles.subscriptionSettingItem}>
              <View style={styles.subscriptionSettingIcon}>
                <MaterialIcons name="event" size={24} color="#8B5CF6" />
              </View>
              <View style={styles.subscriptionSettingContent}>
                <Text style={styles.subscriptionSettingLabel}>End Date</Text>
                <DatePicker
                  selectedDate={subscriptionEndDate}
                  onDateChange={setSubscriptionEndDate}
                  validRange={{
                    startDate: subscriptionStartDate || new Date()
                  }}
                />
                <TouchableOpacity
                  onPress={() => setSubscriptionEndDate(null)}
                  style={{ marginTop: 8 }}
                >
                  <Text style={{ color: "#8B5CF6" }}>
                    {subscriptionEndDate === null ? "✓ " : ""}
                    Ongoing (no end date)
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        )}
      </View>
    );
  };

  // Helper function to get day suffix (1st, 2nd, 3rd, etc.)
  const getDaySuffix = (day) => {
    if (day > 3 && day < 21) return "th";
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const renderInvoiceSummary = () => {
    // Exit early if no invoice items
    if (invoiceItems.length === 0) return null;

    // Calculate the recurring total
    const recurringItems = invoiceItems.filter(
      (item) => item.type === "product" && item.subscriptionInterval
    );
    const recurringTotal = recurringItems.reduce(
      (sum, item) =>
        sum + parseFloat(item.amount) * (parseInt(item.quantity, 10) || 1),
      0
    );

    // Calculate the one-time total
    const oneTimeItems = invoiceItems.filter(
      (item) =>
        item.type === "custom" ||
        (item.type === "product" && !item.subscriptionInterval)
    );
    const oneTimeTotal = oneTimeItems.reduce(
      (sum, item) =>
        sum + parseFloat(item.amount) * (parseInt(item.quantity, 10) || 1),
      0
    );

    // Total before discounts
    const totalBeforeDiscount = recurringTotal + oneTimeTotal;

    // Apply discounts proportionally if applicable
    let discountedRecurringTotal = recurringTotal;
    let discountedOneTimeTotal = oneTimeTotal;
    let discountAmount = 0;

    if (hasDiscounts()) {
      const totalAfterDiscount = parseFloat(calculateDiscountedTotal());
      discountAmount = totalBeforeDiscount - totalAfterDiscount;
      const discountRatio = totalAfterDiscount / totalBeforeDiscount;

      discountedRecurringTotal = (recurringTotal * discountRatio).toFixed(2);
      discountedOneTimeTotal = (oneTimeTotal * discountRatio).toFixed(2);
    }

    return (
      <View style={styles.invoiceSummaryContainer}>
        <Text style={styles.invoiceSummaryTitle}>Invoice Summary</Text>

        {/* Always show the original amounts section first */}
        <View style={styles.originalAmountsSection}>
          {oneTimeItems.length > 0 && (
            <View style={styles.summaryRow}>
              <Text style={styles.summaryLabel}>One-time charge:</Text>
              <Text style={styles.summaryValue}>
                ${oneTimeTotal.toFixed(2)}
              </Text>
            </View>
          )}

          {recurringItems.length > 0 && (
            <>
              <View style={styles.summaryRow}>
                <Text style={styles.summaryLabel}>Recurring charge:</Text>
                <Text style={styles.summaryValue}>
                  ${recurringTotal.toFixed(2)}
                </Text>
              </View>

              {/* Subscription details */}
              {(() => {
                const subscriptionItem = recurringItems.find(() => true);
                if (!subscriptionItem) return null;

                const interval = subscriptionItem.subscriptionInterval;
                const intervalMapping = {
                  day: "daily",
                  week: "weekly",
                  month: "monthly",
                  year: "yearly"
                };
                const intervalText = intervalMapping[interval] || "regularly";

                // Get billing day description
                const getBillingDayText = () => {
                  if (subscriptionStartDate === null) {
                    return "on the day of initial payment";
                  }

                  // Different text based on interval type
                  if (interval === "month") {
                    return `on the ${subscriptionStartDate.getDate()}${getDaySuffix(
                      subscriptionStartDate.getDate()
                    )} of each month`;
                  }
                  if (interval === "week") {
                    const dayNames = [
                      "Sunday",
                      "Monday",
                      "Tuesday",
                      "Wednesday",
                      "Thursday",
                      "Friday",
                      "Saturday"
                    ];
                    return `every ${dayNames[subscriptionStartDate.getDay()]}`;
                  }
                  if (interval === "year") {
                    return `annually on ${subscriptionStartDate.toLocaleDateString(
                      "en-US",
                      { month: "long", day: "numeric" }
                    )}`;
                  }
                  if (interval === "day") {
                    return `daily`;
                  }

                  return "regularly";
                };

                // Format start date
                const startText =
                  subscriptionStartDate === null
                    ? "immediately after payment"
                    : `on ${subscriptionStartDate.toLocaleDateString("en-US", {
                        month: "long",
                        day: "numeric",
                        year: "numeric"
                      })}`;

                // Format end date
                const endText =
                  subscriptionEndDate === null
                    ? "until cancelled"
                    : `until ${subscriptionEndDate.toLocaleDateString("en-US", {
                        month: "long",
                        day: "numeric",
                        year: "numeric"
                      })}`;

                // Calculate billing cycles if end date is set
                let billingCyclesText = "";
                if (subscriptionEndDate !== null) {
                  const cycles = calculateBillingCycles(
                    subscriptionStartDate === null
                      ? new Date()
                      : subscriptionStartDate,
                    subscriptionEndDate,
                    interval
                  );

                  if (cycles !== null) {
                    // Determine proper units based on interval
                    const unitMapping = {
                      day: "day",
                      week: "week",
                      month: "month",
                      year: "year"
                    };
                    const unit = unitMapping[interval] || "year";

                    // Add plural 's' if needed
                    const unitPlural = cycles === 1 ? unit : `${unit}s`;

                    billingCyclesText = ` (${cycles} ${unitPlural} total)`;
                  }
                }

                return (
                  <>
                    <Text style={styles.subscriptionDetail}>
                      This subscription will begin {startText} and will bill{" "}
                      {intervalText} {getBillingDayText()} {endText}
                      {billingCyclesText}.
                    </Text>

                    {subscriptionEndDate !== null && (
                      <View style={styles.billingCyclesSummary}>
                        <Text style={styles.billingCyclesLabel}>
                          Total billing cycles:
                        </Text>
                        <Text style={styles.billingCyclesValue}>
                          {calculateBillingCycles(
                            subscriptionStartDate === null
                              ? new Date()
                              : subscriptionStartDate,
                            subscriptionEndDate,
                            interval
                          )}
                        </Text>
                      </View>
                    )}
                  </>
                );
              })()}
            </>
          )}

          {/* Show original total if there are discounts */}
          {hasDiscounts() && (
            <View style={[styles.summaryRow, styles.originalTotalRow]}>
              <Text style={[styles.summaryLabel, styles.originalTotalLabel]}>
                Subtotal:
              </Text>
              <Text
                style={[
                  styles.summaryValue,
                  hasDiscounts() ? styles.originalPrice : null
                ]}
              >
                ${totalBeforeDiscount.toFixed(2)}
              </Text>
            </View>
          )}
        </View>

        {/* Display discount information if discounts are applied */}
        {hasDiscounts() && selectedDiscounts.length > 0 && (
          <View style={styles.discountSection}>
            <Text style={styles.discountTitle}>Applied Discounts:</Text>
            {selectedDiscounts.map((discount) => (
              <View key={discount.id} style={styles.discountItem}>
                <Text style={styles.discountName}>
                  {discount.name || discount.id}
                  {(() => {
                    if (discount.percent_off) {
                      return ` (${discount.percent_off}% off)`;
                    }
                    if (discount.amount_off) {
                      return ` ($${(discount.amount_off / 100).toFixed(
                        2
                      )} off)`;
                    }
                    return "";
                  })()}
                </Text>
              </View>
            ))}

            <View style={styles.summaryRow}>
              <Text style={styles.summaryLabel}>Total discount:</Text>
              <Text style={styles.discountAmount}>
                -${discountAmount.toFixed(2)}
              </Text>
            </View>

            {/* If discounts are applied, show each discounted item total */}
            {oneTimeItems.length > 0 && (
              <View style={styles.summaryRow}>
                <Text style={styles.summaryLabel}>
                  Discounted one-time charge:
                </Text>
                <Text style={styles.discountedValue}>
                  ${discountedOneTimeTotal}
                </Text>
              </View>
            )}

            {recurringItems.length > 0 && (
              <View style={styles.summaryRow}>
                <Text style={styles.summaryLabel}>
                  Discounted recurring charge:
                </Text>
                <Text style={styles.discountedValue}>
                  ${discountedRecurringTotal}
                </Text>
              </View>
            )}
          </View>
        )}

        <View style={[styles.summaryRow, styles.totalRow]}>
          <Text style={[styles.summaryLabel, styles.totalLabel]}>
            Total amount:
          </Text>
          <Text style={[styles.summaryValue, styles.totalValue]}>
            ${hasDiscounts() ? calculateDiscountedTotal() : calculateTotal()}
          </Text>
        </View>
      </View>
    );
  };

  if (isLoading) {
    return (
      <View style={styles.pageContent}>
        <NavBar navigation={navigation} text="Create Invoice" />
        <ActivityIndicator
          size="large"
          color={primaryColor}
          style={{ marginTop: 60 }}
        />
      </View>
    );
  }

  return (
    <KeyboardAvoidingView
      style={styles.pageContent}
      behavior={Platform.OS === "ios" ? "padding" : "height"}
    >
      <NavBar navigation={navigation} text="Create Invoice" />
      <ScrollView
        style={styles.scrollView}
        contentContainerStyle={styles.scrollViewContent}
      >
        <View style={styles.billingInfoCard}>
          {/* Parent/Customer Information */}
          <View style={styles.customerInfo}>
            <View style={styles.customerIconContainer}>
              <FontAwesome5 name="user-circle" size={36} color={primaryColor} />
            </View>
            <View style={styles.customerTextContainer}>
              <Text style={styles.customerName}>
                {user?.firstName} {user?.lastName}
              </Text>
              <Text style={styles.customerEmail}>{user?.email}</Text>
            </View>
          </View>

          {/* Child Account Information - conditionally rendered */}
          {child && (
            <>
              <View style={styles.separator} />
              <View style={styles.childAccountSection}>
                <View style={styles.childAccountInfo}>
                  <View style={styles.childImageContainer}>
                    {child.profile_photo ? (
                      <Image
                        source={{ uri: child.profile_photo }}
                        style={styles.childImage}
                      />
                    ) : (
                      <View style={styles.childImagePlaceholder}>
                        <Text style={styles.childImagePlaceholderText}>
                          {child.first_name?.[0] || ""}
                          {child.last_name?.[0] || ""}
                        </Text>
                      </View>
                    )}
                  </View>
                  <View style={styles.childTextContainer}>
                    <View style={styles.childLabelContainer}>
                      <MaterialIcons
                        name="person"
                        size={14}
                        color="#718096"
                        style={styles.childLabelIcon}
                      />
                      <Text style={styles.childLabel}>Child</Text>
                    </View>
                    <Text style={styles.childName}>
                      {child.first_name} {child.last_name}
                    </Text>
                  </View>
                </View>
              </View>
            </>
          )}
        </View>

        <View style={styles.form}>
          {/* Items Section with Card-Based Design */}
          <View style={styles.sectionContainer}>
            <Text style={styles.sectionTitle}>Invoice Items</Text>
            {invoiceItems.length > 0 ? (
              invoiceItems.map((item, index) => (
                <View key={`item-${index}`} style={styles.itemCard}>
                  {renderInvoiceItem(item, index)}
                </View>
              ))
            ) : (
              <View style={styles.emptyItemsContainer}>
                <FontAwesome5
                  name="file-invoice-dollar"
                  size={24}
                  color="#CBD5E0"
                />
                <Text style={styles.emptyItemsText}>No items added yet</Text>
                <Text style={styles.emptyItemsSubtext}>
                  Add an item to this invoice
                </Text>
              </View>
            )}
          </View>

          <TouchableOpacity
            style={[styles.addButton, { backgroundColor: primaryColor }]}
            onPress={handleAddItem}
          >
            <MaterialIcons name="add" size={20} color="white" />
            <Text style={styles.addButtonText}>
              {invoiceItems.length > 0 ? "Add Another Item" : "Add Item"}
            </Text>
          </TouchableOpacity>

          {/* Collection Method Selector with enhanced visual indicators */}
          <View style={styles.sectionContainer}>
            <Text style={styles.sectionTitle}>Collection Method</Text>
            <View style={styles.collectionMethodOptions}>
              {hasDefaultPaymentMethod && (
                <TouchableOpacity
                  style={[
                    styles.collectionMethodOption,
                    collectionMethod === "charge_automatically" && [
                      styles.selectedOption,
                      {
                        borderColor: primaryColor,
                        backgroundColor: `${primaryColor}10`
                      }
                    ]
                  ]}
                  onPress={() => setCollectionMethod("charge_automatically")}
                >
                  <View style={styles.radioContainer}>
                    <View
                      style={[
                        styles.radioOuter,
                        collectionMethod === "charge_automatically" && {
                          borderColor: primaryColor
                        }
                      ]}
                    >
                      {collectionMethod === "charge_automatically" && (
                        <View
                          style={[
                            styles.radioInner,
                            { backgroundColor: primaryColor }
                          ]}
                        />
                      )}
                    </View>
                  </View>
                  <FontAwesome5
                    name="credit-card"
                    size={16}
                    color={
                      collectionMethod === "charge_automatically"
                        ? primaryColor
                        : "#718096"
                    }
                    style={styles.optionIcon}
                  />
                  <View style={styles.optionTextContainer}>
                    <Text
                      style={[
                        styles.optionTitle,
                        collectionMethod === "charge_automatically" && {
                          color: primaryColor
                        }
                      ]}
                    >
                      Charge Automatically
                    </Text>
                    <Text style={styles.optionDescription}>
                      Charge customer's payment method immediately
                    </Text>
                  </View>
                </TouchableOpacity>
              )}

              <TouchableOpacity
                style={[
                  styles.collectionMethodOption,
                  collectionMethod === "send_invoice" && [
                    styles.selectedOption,
                    {
                      borderColor: primaryColor,
                      backgroundColor: `${primaryColor}10`
                    }
                  ]
                ]}
                onPress={() => setCollectionMethod("send_invoice")}
              >
                <View style={styles.radioContainer}>
                  <View
                    style={[
                      styles.radioOuter,
                      collectionMethod === "send_invoice" && {
                        borderColor: primaryColor
                      }
                    ]}
                  >
                    {collectionMethod === "send_invoice" && (
                      <View
                        style={[
                          styles.radioInner,
                          { backgroundColor: primaryColor }
                        ]}
                      />
                    )}
                  </View>
                </View>
                <FontAwesome5
                  name="file-invoice-dollar"
                  size={16}
                  color={
                    collectionMethod === "send_invoice"
                      ? primaryColor
                      : "#718096"
                  }
                  style={styles.optionIcon}
                />
                <View style={styles.optionTextContainer}>
                  <Text
                    style={[
                      styles.optionTitle,
                      collectionMethod === "send_invoice" && {
                        color: primaryColor
                      }
                    ]}
                  >
                    Send Invoice
                  </Text>
                  <Text style={styles.optionDescription}>
                    Send invoice for customer to pay later
                  </Text>
                </View>
              </TouchableOpacity>
            </View>

            {/* Due Date Selector (only shown when Send Invoice is selected) */}
            {collectionMethod === "send_invoice" && (
              <View style={styles.dueDateContainer}>
                <Text style={styles.dueDateLabel}>Due Date</Text>
                <DatePicker
                  selectedDate={dueDate}
                  onDateChange={(date) => setDueDate(date)}
                  validRange={{
                    startDate: new Date(
                      new Date().setDate(new Date().getDate() + 1)
                    )
                  }}
                />
                <Text style={styles.dueDateDescription}>
                  Invoice will be due on{" "}
                  {dueDate.toLocaleDateString("en-US", {
                    month: "short",
                    day: "numeric",
                    year: "numeric"
                  })}
                </Text>
              </View>
            )}

            {/* Payment Method Restriction Option */}
            <View style={styles.paymentRestrictionContainer}>
              <Text style={styles.restrictionTitle}>Payment Method</Text>
              <TouchableOpacity
                style={[
                  styles.achRestrictionOption,
                  achOnly && [
                    styles.selectedOption,
                    {
                      borderColor: primaryColor,
                      backgroundColor: `${primaryColor}10`
                    }
                  ]
                ]}
                onPress={() => setAchOnly(!achOnly)}
              >
                <View style={styles.checkboxContainer}>
                  <View
                    style={[
                      styles.checkboxOuter,
                      achOnly && {
                        borderColor: primaryColor,
                        backgroundColor: primaryColor
                      }
                    ]}
                  >
                    {achOnly && (
                      <MaterialIcons name="check" size={14} color="white" />
                    )}
                  </View>
                </View>
                <FontAwesome5
                  name="university"
                  size={16}
                  color={achOnly ? primaryColor : "#718096"}
                  style={styles.optionIcon}
                />
                <View style={styles.optionTextContainer}>
                  <Text
                    style={[
                      styles.optionTitle,
                      achOnly && { color: primaryColor }
                    ]}
                  >
                    ACH Bank Transfer Only
                  </Text>
                  <Text style={styles.optionDescription}>
                    Restrict payment to bank transfers (no credit cards)
                  </Text>
                </View>
              </TouchableOpacity>
            </View>
          </View>

          {/* Add section for selected discounts */}
          <View style={styles.section}>
            <View style={styles.sectionHeader}>
              <Text style={styles.sectionHeaderText}>Discounts</Text>
              <TouchableOpacity
                style={styles.addDiscountButton}
                onPress={() => setShowDiscountSelector(true)}
              >
                <MaterialIcons name="add" size={20} color="white" />
                <Text style={styles.addDiscountButtonText}>Add Discount</Text>
              </TouchableOpacity>
            </View>

            {selectedDiscounts.length > 0 ? (
              <View style={styles.selectedDiscountsContainer}>
                {selectedDiscounts.map((discount) => (
                  <View key={discount.id} style={styles.selectedDiscountItem}>
                    <View style={styles.selectedDiscountInfo}>
                      <Text style={styles.selectedDiscountName}>
                        {discount.name || "Unnamed Discount"}
                      </Text>
                      <Text style={styles.selectedDiscountValue}>
                        {discount.percent_off
                          ? `${discount.percent_off}% off`
                          : `$${(discount.amount_off / 100).toFixed(2)} off`}
                      </Text>
                    </View>
                    <TouchableOpacity
                      style={styles.removeDiscountButton}
                      onPress={() =>
                        setSelectedDiscounts(
                          selectedDiscounts.filter((d) => d.id !== discount.id)
                        )
                      }
                    >
                      <MaterialIcons name="close" size={20} color="#718096" />
                    </TouchableOpacity>
                  </View>
                ))}
              </View>
            ) : (
              <View style={styles.emptyContainer}>
                <MaterialIcons
                  name="loyalty"
                  size={32}
                  color="#CBD5E0"
                  style={styles.emptyIcon}
                />
                <Text style={styles.emptyText}>No discounts added</Text>
              </View>
            )}
          </View>

          {renderSubscriptionSettings()}

          {hasMixedSubscriptionIntervals && (
            <View style={styles.mixedIntervalsWarning}>
              <MaterialIcons
                name="error"
                size={24}
                color="#E53E3E"
                style={{ marginRight: 12, marginTop: 2 }}
              />
              <View style={{ flex: 1 }}>
                <Text
                  style={[
                    styles.mixedIntervalsWarningText,
                    { fontWeight: "600", fontSize: 16, marginBottom: 4 }
                  ]}
                >
                  Mixed Subscription Intervals Detected
                </Text>
                <Text style={styles.mixedIntervalsWarningText}>
                  Your invoice contains subscription products with different
                  billing frequencies (e.g., both monthly and weekly items).
                </Text>
                <Text
                  style={[styles.mixedIntervalsWarningText, { marginTop: 8 }]}
                >
                  Please modify your invoice to only include subscription
                  products with the same billing interval:
                </Text>
                <View style={{ paddingLeft: 10, marginTop: 4 }}>
                  <Text style={styles.mixedIntervalsWarningText}>
                    • Remove subscription products with different intervals, or
                  </Text>
                  <Text style={styles.mixedIntervalsWarningText}>
                    • Create separate invoices for each interval type
                  </Text>
                </View>
              </View>
            </View>
          )}

          {/* New invoice summary section */}
          {invoiceItems.length > 0 &&
            !hasMixedSubscriptionIntervals &&
            renderInvoiceSummary()}

          <TouchableOpacity
            style={[
              styles.submitButton,
              (invoiceItems.length === 0 ||
                invoiceItems.some(
                  (item) =>
                    item.type === "custom" &&
                    (!item.amount || !item.description)
                ) ||
                isSubmitting ||
                hasMixedSubscriptionIntervals) &&
                styles.submitButtonDisabled
            ]}
            onPress={handleCreateInvoice}
            disabled={
              invoiceItems.length === 0 ||
              invoiceItems.some(
                (item) =>
                  item.type === "custom" && (!item.amount || !item.description)
              ) ||
              isSubmitting ||
              hasMixedSubscriptionIntervals
            }
          >
            {isSubmitting ? (
              <ActivityIndicator size="small" color="white" />
            ) : (
              <>
                <MaterialIcons name="check-circle" size={20} color="white" />
                <Text style={styles.submitButtonText}>
                  {hasDiscounts() ? (
                    <>
                      Bill {user?.firstName || "Customer"} $
                      {calculateDiscountedTotal()}
                    </>
                  ) : (
                    <>
                      Bill {user?.firstName || "Customer"} ${calculateTotal()}
                    </>
                  )}
                </Text>
              </>
            )}
          </TouchableOpacity>
        </View>
      </ScrollView>

      <AlertModal alert={alert} setAlert={setAlert} />

      {renderDiscountSelector()}

      {/* Add Item Modal */}
      <Modal
        visible={showAddItemModal}
        animationType="slide"
        transparent
        onRequestClose={() => setShowAddItemModal(false)}
      >
        <View style={styles.modalContainer}>
          <View style={styles.modalOverlay}>
            <TouchableOpacity
              style={{ flex: 1 }}
              activeOpacity={1}
              onPress={() => setShowAddItemModal(false)}
            />
          </View>
          <View style={styles.modalContent}>
            <View style={styles.modalHandle} />
            <View style={styles.modalHeader}>
              <Text style={styles.modalTitle}>Add Item</Text>
              <TouchableOpacity
                onPress={() => setShowAddItemModal(false)}
                hitSlop={{ top: 15, right: 15, bottom: 15, left: 15 }}
                style={styles.closeButton}
              >
                <MaterialIcons name="close" size={24} color="#4A5568" />
              </TouchableOpacity>
            </View>

            <TouchableOpacity
              style={styles.customItemOption}
              onPress={handleAddCustomItem}
            >
              <View style={styles.customItemIconContainer}>
                <FontAwesome5 name="pencil-alt" size={20} color="white" />
              </View>
              <View style={styles.customItemTextContainer}>
                <Text style={styles.customItemTitle}>Add Custom Item</Text>
                <Text style={styles.customItemSubtitle}>
                  Create a custom line item with your own description and amount
                </Text>
              </View>
            </TouchableOpacity>

            <View style={styles.productListHeader}>
              <Text style={styles.productListTitle}>Or select a product:</Text>
            </View>

            {renderProductContent()}
          </View>
        </View>
      </Modal>
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  pageContent: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "#F5F7FA"
  },
  scrollView: {
    width
  },
  scrollViewContent: {
    padding: 20,
    paddingBottom: 50
  },
  billingInfoCard: {
    backgroundColor: "white",
    borderRadius: 16,
    marginBottom: 16,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.08,
    shadowRadius: 5,
    elevation: 3,
    overflow: "hidden"
  },
  customerInfo: {
    flexDirection: "row",
    alignItems: "center",
    padding: 20
  },
  customerIconContainer: {
    marginRight: 16
  },
  customerTextContainer: {
    flex: 1
  },
  customerName: {
    fontSize: 20,
    fontWeight: "700",
    color: "#2D3748",
    marginBottom: 4
  },
  customerEmail: {
    fontSize: 14,
    color: "#718096"
  },
  separator: {
    height: 1,
    backgroundColor: "#E2E8F0",
    marginHorizontal: 16
  },
  childAccountSection: {
    padding: 20,
    paddingTop: 16,
    paddingBottom: 16
  },
  childAccountInfo: {
    flexDirection: "row",
    alignItems: "center"
  },
  childImageContainer: {
    marginRight: 16
  },
  childImage: {
    width: 46,
    height: 46,
    borderRadius: 23
  },
  childImagePlaceholder: {
    width: 46,
    height: 46,
    borderRadius: 23,
    backgroundColor: "#3182CE",
    justifyContent: "center",
    alignItems: "center"
  },
  childImagePlaceholderText: {
    color: "white",
    fontSize: 16,
    fontWeight: "bold"
  },
  childTextContainer: {
    flex: 1
  },
  childLabelContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 4
  },
  childLabelIcon: {
    marginRight: 4
  },
  childLabel: {
    fontSize: 12,
    color: "#718096",
    fontWeight: "500"
  },
  childName: {
    fontSize: 16,
    fontWeight: "600",
    color: "#2D3748"
  },
  form: {
    backgroundColor: "white",
    borderRadius: 16,
    padding: 24,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.1,
    shadowRadius: 8,
    elevation: 5
  },
  invoiceItemCompact: {
    backgroundColor: "#FAFBFC",
    borderRadius: 8,
    overflow: "hidden"
  },
  itemHeaderCompact: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 12,
    paddingVertical: 10,
    borderBottomWidth: 1,
    borderBottomColor: "#F0F0F0"
  },
  itemTitleRow: {
    flexDirection: "row",
    alignItems: "center"
  },
  itemTitleCompact: {
    fontSize: 14,
    fontWeight: "600",
    marginLeft: 6,
    color: "#333"
  },
  customItemContent: {
    padding: 12
  },
  customItemRow: {
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between"
  },
  amountInputContainerCompact: {
    width: "30%"
  },
  descriptionInputContainerCompact: {
    width: "68%"
  },
  inputLabelCompact: {
    fontSize: 12,
    color: "#718096",
    marginBottom: 4
  },
  amountInputWrapperCompact: {
    flexDirection: "row",
    alignItems: "center",
    borderWidth: 1,
    borderColor: "#E2E8F0",
    borderRadius: 6,
    height: 38,
    backgroundColor: "#F7FAFC",
    paddingHorizontal: 8
  },
  currencySymbolCompact: {
    color: "#718096",
    marginRight: 2
  },
  amountInputCompact: {
    flex: 1,
    height: 38,
    padding: 0,
    fontSize: 14
  },
  descriptionInputCompact: {
    borderWidth: 1,
    borderColor: "#E2E8F0",
    borderRadius: 6,
    height: 38,
    backgroundColor: "#F7FAFC",
    paddingHorizontal: 8,
    fontSize: 14
  },
  productItemContainerCompact: {
    flexDirection: "row",
    padding: 12,
    alignItems: "center"
  },
  productImageCompact: {
    width: 36,
    height: 36,
    borderRadius: 6
  },
  productImagePlaceholder: {
    width: 36,
    height: 36,
    borderRadius: 6,
    marginRight: 12,
    justifyContent: "center",
    alignItems: "center"
  },
  productDetailsCompact: {
    flex: 1,
    marginLeft: 10
  },
  productNameCompact: {
    fontSize: 14,
    fontWeight: "500",
    color: "#2D3748"
  },
  productPriceCompact: {
    fontSize: 13,
    fontWeight: "600",
    color: "#4A5568",
    marginTop: 2
  },
  quantityControlsCompact: {
    flexDirection: "row",
    alignItems: "center",
    borderWidth: 1,
    borderColor: "#E2E8F0",
    borderRadius: 6,
    overflow: "hidden",
    marginLeft: 10
  },
  quantityButtonCompact: {
    width: 28,
    height: 28,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F7FAFC"
  },
  quantityInputCompact: {
    width: 30,
    height: 28,
    textAlign: "center",
    padding: 0,
    fontSize: 14,
    fontWeight: "500",
    backgroundColor: "white"
  },
  itemTotalCompact: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingHorizontal: 12,
    paddingBottom: 10,
    paddingTop: 4,
    borderTopWidth: 1,
    borderTopColor: "#F0F0F0"
  },
  itemTotalLabelCompact: {
    fontSize: 13,
    color: "#718096"
  },
  itemTotalAmountCompact: {
    fontSize: 15,
    fontWeight: "600",
    color: "#2D3748",
    marginLeft: 6
  },
  subscriptionBadge: {
    paddingHorizontal: 6,
    paddingVertical: 2,
    borderRadius: 8,
    marginLeft: 6
  },
  subscriptionBadgeText: {
    color: "white",
    fontSize: 10,
    fontWeight: "bold"
  },
  addButton: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 12,
    padding: 14,
    marginTop: 16,
    marginBottom: 32
  },
  addButtonText: {
    color: "white",
    fontWeight: "600",
    fontSize: 16,
    marginLeft: 8
  },
  submitButton: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#2DD881",
    borderRadius: 12,
    padding: 16,
    marginTop: 24
  },
  submitButtonText: {
    color: "white",
    fontWeight: "600",
    fontSize: 18,
    marginLeft: 8
  },
  emptyItemsContainer: {
    padding: 32,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#F7FAFC",
    borderRadius: 12,
    borderWidth: 1,
    borderColor: "#EDF2F7",
    borderStyle: "dashed"
  },
  emptyItemsText: {
    fontSize: 16,
    color: "#4A5568",
    marginTop: 12
  },
  emptyItemsSubtext: {
    fontSize: 14,
    color: "#718096",
    marginTop: 4
  },
  itemCard: {
    backgroundColor: "white",
    borderRadius: 12,
    marginBottom: 16,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.05,
    shadowRadius: 3,
    elevation: 2,
    borderWidth: 1,
    borderColor: "#EDF2F7",
    overflow: "hidden"
  },
  sectionContainer: {
    marginBottom: 28
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: "600",
    color: "#2D3748",
    marginBottom: 16,
    paddingBottom: 8,
    borderBottomWidth: 1,
    borderBottomColor: "#EDF2F7"
  },
  collectionMethodOptions: {
    gap: 16
  },
  collectionMethodOption: {
    flexDirection: "row",
    alignItems: "center",
    padding: 16,
    borderWidth: 1,
    borderColor: "#E2E8F0",
    borderRadius: 12,
    backgroundColor: "#F7FAFC"
  },
  selectedOption: {
    borderWidth: 2
  },
  radioContainer: {
    width: 22,
    height: 22,
    justifyContent: "center",
    alignItems: "center",
    marginRight: 12
  },
  radioOuter: {
    width: 18,
    height: 18,
    borderWidth: 2,
    borderColor: "#CBD5E0",
    borderRadius: 9,
    justifyContent: "center",
    alignItems: "center"
  },
  radioInner: {
    width: 10,
    height: 10,
    borderRadius: 5
  },
  optionIcon: {
    marginRight: 12
  },
  optionTextContainer: {
    flex: 1
  },
  optionTitle: {
    fontSize: 16,
    fontWeight: "600",
    color: "#2D3748",
    marginBottom: 4
  },
  optionDescription: {
    fontSize: 14,
    color: "#718096"
  },
  modalOverlay: {
    flex: 1,
    backgroundColor: "transparent"
  },
  modalContainer: {
    flex: 1,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    justifyContent: "flex-end",
    alignItems: "center"
  },
  modalContent: {
    backgroundColor: "#FFFFFF",
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    overflow: "hidden",
    maxHeight: "80%",
    width,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: -4 },
    shadowOpacity: 0.1,
    shadowRadius: 8,
    elevation: 5
  },
  modalHandle: {
    width: 40,
    height: 5,
    backgroundColor: "#E2E8F0",
    borderRadius: 3,
    alignSelf: "center",
    marginTop: 10
  },
  modalHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 16,
    paddingTop: 10,
    borderBottomWidth: 1,
    borderBottomColor: "#E2E8F0"
  },
  modalTitle: {
    fontSize: 18,
    fontWeight: "600",
    color: "#2D3748"
  },
  modalBodyContent: {
    paddingBottom: 20
  },
  customItemOption: {
    flexDirection: "row",
    padding: 16,
    borderBottomWidth: 1,
    borderBottomColor: "#E2E8F0",
    alignItems: "center"
  },
  customItemIconContainer: {
    width: 40,
    height: 40,
    borderRadius: 20,
    backgroundColor: "#4299E1",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 12
  },
  customItemTextContainer: {
    flex: 1
  },
  customItemTitle: {
    fontSize: 16,
    fontWeight: "600",
    color: "#2D3748",
    marginBottom: 4
  },
  customItemSubtitle: {
    fontSize: 14,
    color: "#718096"
  },
  productListHeader: {
    padding: 16,
    backgroundColor: "#F7FAFC"
  },
  productListTitle: {
    fontSize: 16,
    fontWeight: "600",
    color: "#4A5568"
  },
  productsLoading: {
    padding: 20
  },
  productList: {
    maxHeight: 300
  },
  productOption: {
    flexDirection: "row",
    padding: 16,
    borderBottomWidth: 1,
    borderBottomColor: "#E2E8F0",
    alignItems: "center"
  },
  productOptionImage: {
    width: 50,
    height: 50,
    borderRadius: 8,
    marginRight: 12
  },
  productOptionImagePlaceholder: {
    width: 50,
    height: 50,
    borderRadius: 8,
    marginRight: 12,
    justifyContent: "center",
    alignItems: "center"
  },
  productOptionDetails: {
    flex: 1
  },
  productOptionName: {
    fontSize: 16,
    fontWeight: "600",
    color: "#2D3748",
    marginBottom: 4
  },
  productOptionPrice: {
    fontSize: 14,
    fontWeight: "700",
    color: "#4A5568"
  },
  noProductsContainer: {
    padding: 20,
    alignItems: "center"
  },
  noProductsText: {
    fontSize: 16,
    color: "#718096",
    textAlign: "center"
  },
  paymentRestrictionContainer: {
    marginTop: 20
  },
  restrictionTitle: {
    fontSize: 16,
    fontWeight: "600",
    color: "#2D3748",
    marginBottom: 12
  },
  achRestrictionOption: {
    flexDirection: "row",
    alignItems: "center",
    padding: 16,
    borderWidth: 1,
    borderColor: "#E2E8F0",
    borderRadius: 12,
    backgroundColor: "#F7FAFC"
  },
  checkboxContainer: {
    width: 22,
    height: 22,
    justifyContent: "center",
    alignItems: "center",
    marginRight: 12
  },
  checkboxOuter: {
    width: 18,
    height: 18,
    borderWidth: 2,
    borderColor: "#CBD5E0",
    borderRadius: 4,
    justifyContent: "center",
    alignItems: "center"
  },
  dueDateContainer: {
    marginTop: 20,
    paddingTop: 16,
    borderTopWidth: 1,
    borderTopColor: "#EDF2F7"
  },
  dueDateLabel: {
    fontSize: 16,
    fontWeight: "600",
    color: "#2D3748",
    marginBottom: 12
  },
  dueDateDescription: {
    fontSize: 14,
    color: "#718096",
    fontStyle: "italic"
  },
  section: {
    backgroundColor: "white",
    borderRadius: 12,
    marginBottom: 16,
    borderWidth: 1,
    borderColor: "#EDF2F7",
    overflow: "hidden"
  },
  sectionHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 16,
    borderBottomWidth: 1,
    borderBottomColor: "#EDF2F7"
  },
  sectionHeaderText: {
    fontSize: 18,
    fontWeight: "600",
    color: "#2D3748"
  },
  addDiscountButton: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#4299E1",
    paddingHorizontal: 16,
    paddingVertical: 8,
    borderRadius: 8
  },
  addDiscountButtonText: {
    color: "white",
    fontWeight: "600",
    fontSize: 14,
    marginLeft: 8
  },
  emptyContainer: {
    padding: 24,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#F9FAFC",
    borderRadius: 8,
    margin: 16
  },
  emptyIcon: {
    marginBottom: 12
  },
  emptyText: {
    fontSize: 16,
    color: "#718096",
    textAlign: "center"
  },
  selectedDiscountsContainer: {
    padding: 16
  },
  selectedDiscountItem: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#EBF8FF",
    padding: 12,
    borderRadius: 8,
    marginBottom: 8
  },
  selectedDiscountInfo: {
    flex: 1
  },
  selectedDiscountName: {
    fontSize: 16,
    fontWeight: "500",
    color: "#2C5282"
  },
  selectedDiscountValue: {
    fontSize: 14,
    color: "#4299E1"
  },
  removeDiscountButton: {
    padding: 8,
    borderRadius: 20
  },
  closeButton: {
    padding: 4
  },
  modalBody: {
    maxHeight: "70%",
    padding: 16
  },
  emptyDiscountsContainer: {
    padding: 32,
    alignItems: "center",
    justifyContent: "center"
  },
  emptyStateIcon: {
    marginBottom: 16
  },
  emptyDiscountsText: {
    fontSize: 16,
    color: "#718096",
    marginBottom: 16,
    textAlign: "center"
  },
  createDiscountButton: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#2DD881",
    paddingHorizontal: 20,
    paddingVertical: 12,
    borderRadius: 12,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 2
  },
  createDiscountButtonText: {
    color: "white",
    fontWeight: "600",
    fontSize: 16,
    marginLeft: 8
  },
  modalFooter: {
    flexDirection: "row",
    justifyContent: "center",
    padding: 16,
    paddingBottom: 32,
    borderTopWidth: 1,
    borderTopColor: "#E2E8F0"
  },
  discountOptionContent: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%"
  },
  discountName: {
    fontSize: 16,
    fontWeight: "600",
    color: "#2D3748",
    marginBottom: 4
  },
  discountValue: {
    fontSize: 14,
    color: "#2DD881",
    fontWeight: "500"
  },
  discountsContentContainer: {
    flex: 1
  },
  createNewDiscountButton: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#F7FAFC",
    paddingHorizontal: 20,
    paddingVertical: 12,
    borderRadius: 12,
    marginBottom: 16,
    borderWidth: 1,
    borderColor: "#E2E8F0",
    borderStyle: "dashed"
  },
  createNewDiscountButtonText: {
    color: "#6C63FF",
    fontWeight: "600",
    fontSize: 16,
    marginLeft: 8
  },
  discountsList: {
    borderRadius: 12,
    overflow: "hidden",
    borderWidth: 1,
    borderColor: "#E2E8F0"
  },
  discountOption: {
    padding: 16,
    borderBottomWidth: 1,
    borderBottomColor: "#E2E8F0",
    backgroundColor: "#FFFFFF"
  },
  selectedDiscountOption: {
    backgroundColor: "#F5F3FF"
  },
  sectionContent: {
    padding: 16
  },
  subscriptionSettingItem: {
    flexDirection: "row",
    alignItems: "center",
    padding: 12,
    borderBottomWidth: 1,
    borderBottomColor: "#EDF2F7"
  },
  subscriptionSettingIcon: {
    width: 40,
    height: 40,
    borderRadius: 20,
    backgroundColor: "#F7FAFC",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 12
  },
  subscriptionSettingContent: {
    flex: 1
  },
  subscriptionSettingLabel: {
    fontSize: 16,
    fontWeight: "600",
    color: "#2D3748",
    marginBottom: 4
  },
  mixedIntervalsWarning: {
    flexDirection: "row",
    alignItems: "flex-start",
    backgroundColor: "#FFF5F5",
    borderRadius: 12,
    padding: 16,
    marginTop: 16,
    marginBottom: 16,
    borderWidth: 1,
    borderColor: "#FED7D7"
  },
  mixedIntervalsWarningText: {
    color: "#C53030",
    fontSize: 14,
    lineHeight: 20
  },
  submitButtonDisabled: {
    backgroundColor: "#A0AEC0",
    opacity: 0.7
  },
  invoiceSummaryContainer: {
    marginTop: 24,
    marginBottom: 24,
    padding: 16,
    backgroundColor: "#F7FAFC",
    borderRadius: 12,
    borderWidth: 1,
    borderColor: "#E2E8F0"
  },
  invoiceSummaryTitle: {
    fontSize: 18,
    fontWeight: "600",
    color: "#2D3748",
    marginBottom: 16
  },
  summaryRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 8
  },
  summaryLabel: {
    fontSize: 16,
    color: "#4A5568"
  },
  summaryValue: {
    fontSize: 16,
    fontWeight: "500",
    color: "#2D3748"
  },
  totalRow: {
    marginTop: 8,
    paddingTop: 12,
    borderTopWidth: 1,
    borderTopColor: "#E2E8F0"
  },
  totalLabel: {
    fontWeight: "600"
  },
  totalValue: {
    fontWeight: "700"
  },
  subscriptionDetail: {
    fontSize: 14,
    color: "#4A5568",
    lineHeight: 20,
    marginTop: 12,
    marginBottom: 12,
    fontStyle: "italic"
  },
  discountSection: {
    marginTop: 8,
    marginBottom: 8,
    paddingTop: 8,
    paddingBottom: 8,
    borderTopWidth: 1,
    borderTopColor: "#E2E8F0",
    borderBottomWidth: 1,
    borderBottomColor: "#E2E8F0"
  },
  discountTitle: {
    fontSize: 15,
    fontWeight: "600",
    color: "#4A5568",
    marginBottom: 8
  },
  discountItem: {
    marginBottom: 4,
    paddingLeft: 8
  },
  discountAmount: {
    fontSize: 16,
    fontWeight: "500",
    color: "#38A169" // Green color for discounts
  },
  originalPrice: {
    textDecorationLine: "line-through",
    color: "#718096" // Gray color for the original price
  },
  originalAmountsSection: {
    marginBottom: 16
  },
  originalTotalRow: {
    marginTop: 8
  },
  originalTotalLabel: {
    fontWeight: "600"
  },
  discountedValue: {
    fontSize: 16,
    fontWeight: "500",
    color: "#2DD881"
  },
  billingCyclesSummary: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 8
  },
  billingCyclesLabel: {
    fontSize: 14,
    color: "#718096"
  },
  billingCyclesValue: {
    fontSize: 14,
    fontWeight: "600",
    color: "#2D3748"
  }
});
