import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  KeyboardAvoidingView,
  Platform,
  TouchableOpacity
} from "react-native";
import Glob from "src/globalConstants";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import NavBar from "src/components/navBar";
import { MaterialIcons } from "@expo/vector-icons";
import SearchBar from "src/components/searchBar";
import moment from "moment";
import Button from "src/components/Button";
import BottomSheetModal from "src/components/BottomSheetModal";
import Dropdown from "src/components/Dropdown";
import { Skeleton } from "native-base";

const { width } = Glob.get("dimensions");
const DEFAULT_INVOICES_TO_SHOW = 5;
const LOADING = "LOADING";

const DashboardCard = ({ title, children, icon }) => (
  <View style={styles.card}>
    <View style={styles.cardHeader}>
      {icon}
      <Text style={styles.cardTitle}>{title}</Text>
    </View>
    {children}
  </View>
);

const InvoiceSkeletonLoader = () => (
  <View style={{ width: "100%" }}>
    {/* Summary amount skeleton */}
    <View style={[styles.skeletonSummaryContainer, { paddingVertical: 25 }]}>
      <Skeleton h="8" w="40%" rounded="md" startColor="#eee" endColor="#ddd" />
      <Skeleton
        h="4"
        w="30%"
        rounded="full"
        mt="2"
        startColor="#eee"
        endColor="#ddd"
      />
    </View>

    {/* Search bar skeleton */}
    <View style={styles.skeletonSearchBarContainer}>
      <Skeleton h="10" rounded="md" startColor="#eee" endColor="#ddd" />
    </View>

    {/* Invoice items */}
    {[1, 2, 3].map((item) => (
      <View key={item} style={styles.skeletonInvoiceItem}>
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <View>
            <Skeleton
              h="4"
              w="120"
              rounded="full"
              mb="2"
              startColor="#eee"
              endColor="#ddd"
            />
            <Skeleton
              h="3"
              w="150"
              rounded="full"
              startColor="#eee"
              endColor="#ddd"
            />
          </View>
          <Skeleton
            h="6"
            w="70"
            rounded="md"
            startColor="#eee"
            endColor="#ddd"
          />
        </View>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: 12
          }}
        >
          <Skeleton
            h="3"
            w="100"
            rounded="full"
            startColor="#eee"
            endColor="#ddd"
          />
          <Skeleton
            h="3"
            w="10"
            rounded="full"
            startColor="#eee"
            endColor="#ddd"
          />
        </View>
      </View>
    ))}
  </View>
);

const PaymentHistorySkeletonLoader = () => (
  <View style={{ width: "100%" }}>
    {[1, 2, 3].map((item) => (
      <View key={item} style={styles.skeletonInvoiceItem}>
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <View>
            <Skeleton
              h="4"
              w={100 + item * 20}
              rounded="full"
              mb="2"
              startColor="#eee"
              endColor="#ddd"
            />
            <Skeleton
              h="3"
              w={130 + item * 10}
              rounded="full"
              startColor="#eee"
              endColor="#ddd"
            />
          </View>
          <View style={{ alignItems: "flex-end" }}>
            <Skeleton
              h="6"
              w="70"
              rounded="md"
              startColor="#eee"
              endColor="#ddd"
            />
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginTop: 4
              }}
            >
              <Skeleton
                h="2"
                w="2"
                rounded="full"
                startColor="#2DD881"
                endColor="#2DD881"
                mr="1"
              />
              <Skeleton
                h="3"
                w="30"
                rounded="full"
                startColor="#eee"
                endColor="#ddd"
              />
            </View>
          </View>
        </View>
      </View>
    ))}

    {/* View All Payments button skeleton */}
    <View
      style={[styles.historyButton, { marginTop: 15, marginHorizontal: 4 }]}
    >
      <Skeleton
        h="4"
        w="60%"
        rounded="full"
        startColor="#eee"
        endColor="#ddd"
      />
    </View>
  </View>
);

const InvoiceItem = ({ invoice, onPress }) => {
  // Determine status color and text based on invoice state
  let statusColor = "#F6AD55"; // Default: orange for unpaid
  let statusText = "Awaiting payment";

  if (invoice.paid) {
    statusColor = "#2DD881"; // Green for paid
    statusText = "Paid";
  } else if (invoice.collection_method === "send_invoice") {
    statusColor = "#3182CE"; // Blue for invoice sent
    statusText = "Invoice sent";
  }

  // Check if invoice has discounts
  const hasDiscounts = invoice.discounts && invoice.discounts.length > 0;

  return (
    <TouchableOpacity style={styles.invoiceItem} onPress={onPress}>
      <View style={styles.invoiceHeader}>
        <View>
          <Text style={styles.customerName}>{invoice.customer_name}</Text>
          <Text style={styles.customerEmail}>{invoice.customer_email}</Text>
        </View>
        <View style={styles.amountContainer}>
          {hasDiscounts && <Text style={styles.discountBadge}>Discounted</Text>}
          <Text
            style={[
              styles.amount,
              invoice.paid ? styles.paidAmount : styles.unpaidAmount
            ]}
          >
            ${(invoice.amount_due / 100).toFixed(2)}
          </Text>
        </View>
      </View>
      <View style={styles.invoiceFooter}>
        <Text style={styles.invoiceDate}>
          {invoice.paid ? "Paid on " : "Created on "}
          {moment(
            (invoice.paid &&
            invoice.status_transitions &&
            invoice.status_transitions.paid_at
              ? invoice.status_transitions.paid_at
              : invoice.created) * 1000
          ).format("MMM D, YYYY")}
        </Text>
        <View style={styles.statusContainer}>
          <View style={[styles.statusDot, { backgroundColor: statusColor }]} />
          <Text style={styles.statusText}>{statusText}</Text>
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default function CommerceBilling({ navigation }) {
  const [isLoadingUnpaidInvoices, setIsLoadingUnpaidInvoices] = useState(true);
  const [isLoadingPayments, setIsLoadingPayments] = useState(true);
  const [unpaidInvoices, setUnpaidInvoices] = useState([]);
  const [paidInvoices, setPaidInvoices] = useState([]);
  const [filteredUnpaidInvoices, setFilteredUnpaidInvoices] = useState([]);
  const [filteredPaidInvoices, setFilteredPaidInvoices] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [paymentSearchQuery, setPaymentSearchQuery] = useState("");
  const [showCustomerPortal, setShowCustomerPortal] = useState(false);
  const [customerPortalURL, setCustomerPortalURL] = useState(null);
  const [showUserDropdown, setShowUserDropdown] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [totalPaid, setTotalPaid] = useState(LOADING);
  const [totalUnpaid, setTotalUnpaid] = useState(LOADING);
  const [isShowingAllUnpaidInvoices, setIsShowingAllUnpaidInvoices] = useState(
    false
  );
  const [isShowingAllPayments, setIsShowingAllPayments] = useState(false);

  useEffect(() => {
    Analytics.logEvent("view_commerceBilling");
    fetchUnpaidInvoices();
    fetchPaidInvoices();
    fetchUsers();
    fetchAnalytics();
  }, []);

  const fetchAnalytics = async () => {
    const analyticsResponse = await Database.stripeConnectedAccountFetchInvoiceAnalytics();
    if (analyticsResponse.success) {
      const { totalPaid: paid, totalUnpaid: unpaid } = analyticsResponse;
      setTotalPaid(paid);
      setTotalUnpaid(unpaid);
    }
  };

  const fetchUnpaidInvoices = async (fetchAll = false) => {
    setIsLoadingUnpaidInvoices(true);
    try {
      // Fetch unpaid invoices
      const unpaidInvoicesResponse = await Database.stripeConnectedAccountFetchAllInvoices(
        { unpaidOnly: true, limit: fetchAll ? 100 : DEFAULT_INVOICES_TO_SHOW }
      );

      const unpaidInvoicesData = unpaidInvoicesResponse.invoices || [];
      setUnpaidInvoices(unpaidInvoicesData);
      setFilteredUnpaidInvoices(unpaidInvoicesData);
      setIsShowingAllUnpaidInvoices(fetchAll);
    } catch (error) {
      console.error("Error fetching unpaid invoices:", error);
    } finally {
      setIsLoadingUnpaidInvoices(false);
    }
  };

  const fetchPaidInvoices = async (fetchAll = false) => {
    setIsLoadingPayments(true);
    try {
      // Fetch paid invoices. TODO: Implement pagination
      const paidInvoicesResponse = await Database.stripeConnectedAccountFetchAllInvoices(
        { paidOnly: true, limit: fetchAll ? 100 : DEFAULT_INVOICES_TO_SHOW }
      );

      const paidInvoicesData = paidInvoicesResponse.invoices || [];
      setPaidInvoices(paidInvoicesData);
      setFilteredPaidInvoices(paidInvoicesData);
      setIsShowingAllPayments(fetchAll);
    } catch (error) {
      console.error("Error fetching paid invoices:", error);
    } finally {
      setIsLoadingPayments(false);
    }
  };

  const loadAllPayments = () => {
    Analytics.logEvent("touch_viewAllPaymentHistory");
    fetchPaidInvoices(true);
  };

  const loadAllUnpaidInvoices = () => {
    Analytics.logEvent("touch_viewAllUnpaidInvoices");
    fetchUnpaidInvoices(true);
  };

  const fetchUsers = async () => {
    try {
      const allUsers = await Database.fetchAllUsers();
      const userItems = allUsers.map((user) => ({
        value: user.uid,
        text: `${user.firstName} ${user.lastName} (${user.email})`
      }));
      setUsers(userItems);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
    if (!query.trim()) {
      setFilteredUnpaidInvoices(unpaidInvoices);
      return;
    }

    const filtered = unpaidInvoices.filter((invoice) => {
      const searchString = `${invoice.customer_name} ${invoice.customer_email} ${invoice.amount_due}`.toLowerCase();
      return searchString.includes(query.toLowerCase());
    });
    setFilteredUnpaidInvoices(filtered);
  };

  const handlePaymentSearch = (query) => {
    setPaymentSearchQuery(query);
    if (!query.trim()) {
      setFilteredPaidInvoices(paidInvoices);
      return;
    }

    const filtered = paidInvoices.filter((invoice) => {
      const searchString = `${invoice.customer_name} ${invoice.customer_email} ${invoice.amount_paid}`.toLowerCase();
      return searchString.includes(query.toLowerCase());
    });
    setFilteredPaidInvoices(filtered);
  };

  const handleUserSelect = async (userID) => {
    setSelectedUser(userID);
    setShowUserDropdown(false);
    handleCreateInvoice(userID);
  };

  const handleCreateInvoice = (userID) => {
    navigation.push("commerceCreateInvoice", {
      userID,
      onInvoiceCreated: () => {
        fetchUnpaidInvoices(isShowingAllUnpaidInvoices);
        fetchPaidInvoices(isShowingAllPayments);
      }
    });
  };

  const handleInvoicePress = (invoice) => {
    navigation.push("commerceInvoiceDetails", {
      invoice,
      onInvoiceUpdated: () => {
        fetchUnpaidInvoices(isShowingAllUnpaidInvoices);
        fetchPaidInvoices(isShowingAllPayments);
      }
    });
  };

  return (
    <>
      <KeyboardAvoidingView
        style={styles.pageContent}
        behavior={Platform.OS === "ios" ? "padding" : "height"}
      >
        <NavBar navigation={navigation} text="Billing & Invoices" />
        <ScrollView
          style={styles.scrollView}
          contentContainerStyle={styles.scrollViewContent}
        >
          <View style={[styles.card, styles.searchContainer]}>
            {showUserDropdown ? (
              <Dropdown
                placeholder="Who do you want to bill?"
                items={users}
                value={selectedUser}
                onSelect={handleUserSelect}
                enableAutocomplete
                startOpen
                onCollapse={() => setShowUserDropdown(false)}
              />
            ) : (
              <Button
                text="Bill Someone"
                onPress={() =>
                  navigation.push("commerceBillSomeone", {
                    onInvoiceCreated: () => {
                      fetchUnpaidInvoices(isShowingAllUnpaidInvoices);
                      fetchPaidInvoices(isShowingAllPayments);
                    }
                  })
                }
                style={styles.createButton}
                textStyle={{ fontWeight: "bold" }}
              />
            )}
          </View>

          <DashboardCard
            title="Outstanding Payments"
            icon={<MaterialIcons name="payment" size={24} color="#4A5568" />}
          >
            {isLoadingUnpaidInvoices ? (
              <InvoiceSkeletonLoader />
            ) : (
              <>
                <View style={styles.summaryContainer}>
                  {totalUnpaid === LOADING ? (
                    <Skeleton
                      h="8"
                      w="50%"
                      rounded="md"
                      startColor="#eee"
                      endColor="#ddd"
                      speed={3}
                    />
                  ) : (
                    <Text style={styles.summaryAmount}>
                      $
                      {(totalUnpaid / 100).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </Text>
                  )}
                  <Text style={styles.summaryLabel}>
                    {totalUnpaid === LOADING
                      ? "Calculating Total Outstanding..."
                      : "Total Outstanding"}
                  </Text>
                </View>
                <View style={styles.searchBarContainer}>
                  {isShowingAllUnpaidInvoices && (
                    <SearchBar
                      placeholder="Search unpaid invoices..."
                      onChangeText={handleSearch}
                      value={searchQuery}
                      customStyle={styles.searchBar}
                    />
                  )}
                </View>
                {filteredUnpaidInvoices.map((invoice) => (
                  <InvoiceItem
                    key={invoice.id}
                    invoice={invoice}
                    onPress={() => handleInvoicePress(invoice)}
                  />
                ))}

                {!isShowingAllUnpaidInvoices &&
                  filteredUnpaidInvoices.length >= DEFAULT_INVOICES_TO_SHOW && (
                    <TouchableOpacity
                      style={[styles.historyButton, { marginTop: 15 }]}
                      onPress={loadAllUnpaidInvoices}
                      disabled={isLoadingUnpaidInvoices}
                    >
                      <Text style={styles.historyButtonText}>
                        View All Unpaid Invoices
                      </Text>
                      <MaterialIcons
                        name="expand-more"
                        size={20}
                        color="#4A5568"
                      />
                    </TouchableOpacity>
                  )}
              </>
            )}
          </DashboardCard>

          <DashboardCard
            title="Payment History"
            icon={
              <MaterialIcons name="receipt-long" size={24} color="#4A5568" />
            }
          >
            {isLoadingPayments ? (
              <PaymentHistorySkeletonLoader />
            ) : (
              <>
                <View style={styles.summaryContainer}>
                  {totalPaid === LOADING ? (
                    <Skeleton
                      h="8"
                      w="50%"
                      rounded="md"
                      startColor="#eee"
                      endColor="#ddd"
                      speed={3}
                    />
                  ) : (
                    <Text style={styles.summaryAmount}>
                      $
                      {(totalPaid / 100).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </Text>
                  )}
                  <Text style={styles.summaryLabel}>
                    {totalPaid === LOADING
                      ? "Calculating Total Received..."
                      : "Total Received"}
                  </Text>
                </View>
                <View style={styles.searchBarContainer}>
                  {isShowingAllPayments && (
                    <SearchBar
                      placeholder="Search payment history..."
                      onChangeText={handlePaymentSearch}
                      value={paymentSearchQuery}
                      customStyle={styles.searchBar}
                    />
                  )}
                </View>
                {paidInvoices.length > 0 ? (
                  <>
                    {(isShowingAllPayments
                      ? filteredPaidInvoices
                      : paidInvoices
                    ).map((invoice) => (
                      <InvoiceItem
                        key={invoice.id}
                        invoice={invoice}
                        onPress={() => handleInvoicePress(invoice)}
                      />
                    ))}

                    {!isShowingAllPayments && (
                      <TouchableOpacity
                        style={[styles.historyButton, { marginTop: 15 }]}
                        onPress={loadAllPayments}
                        disabled={isLoadingPayments}
                      >
                        <Text style={styles.historyButtonText}>
                          View All Payments
                        </Text>
                        <MaterialIcons
                          name="expand-more"
                          size={20}
                          color="#4A5568"
                        />
                      </TouchableOpacity>
                    )}
                  </>
                ) : (
                  <Text style={styles.emptyText}>No payment history</Text>
                )}
              </>
            )}
          </DashboardCard>
        </ScrollView>
      </KeyboardAvoidingView>
      {/* TODO: This is not yet used. Consider using this to allow customers to manage/cancel their subscriptions */}
      {showCustomerPortal && customerPortalURL && (
        <BottomSheetModal
          onClose={() => {
            setShowCustomerPortal(false);
            setCustomerPortalURL(null);
            fetchUnpaidInvoices(isShowingAllUnpaidInvoices);
            fetchPaidInvoices(isShowingAllPayments);
          }}
          url={customerPortalURL}
          fullestStyleNative
        />
      )}
    </>
  );
}

const styles = StyleSheet.create({
  pageContent: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "#F5F7FA"
  },
  scrollView: {
    width
  },
  scrollViewContent: {
    padding: 20,
    paddingBottom: 50
  },
  searchContainer: {
    marginBottom: 20,
    width: "100%",
    padding: 16
  },
  createButton: {
    backgroundColor: "#2DD881"
  },
  card: {
    backgroundColor: "white",
    borderRadius: 12,
    padding: 20,
    marginBottom: 20,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 3
  },
  cardHeader: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 16
  },
  cardTitle: {
    fontSize: 18,
    fontWeight: "600",
    color: "#2D3748",
    marginLeft: 12
  },
  summaryContainer: {
    backgroundColor: "#F7FAFC",
    borderRadius: 8,
    padding: 16,
    marginBottom: 16,
    alignItems: "center"
  },
  summaryAmount: {
    fontSize: 32,
    fontWeight: "bold",
    color: "#2D3748"
  },
  summaryLabel: {
    fontSize: 14,
    color: "#718096",
    marginTop: 4
  },
  invoiceItem: {
    borderWidth: 1,
    borderColor: "#E2E8F0",
    borderRadius: 8,
    padding: 16,
    marginBottom: 12
  },
  invoiceHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginBottom: 12
  },
  customerName: {
    fontSize: 16,
    fontWeight: "600",
    color: "#2D3748"
  },
  customerEmail: {
    fontSize: 14,
    color: "#718096"
  },
  amountContainer: {
    alignItems: "flex-end"
  },
  amount: {
    fontSize: 18,
    fontWeight: "600"
  },
  paidAmount: {
    color: "#2DD881"
  },
  unpaidAmount: {
    color: "#F6AD55"
  },
  invoiceFooter: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  invoiceDate: {
    fontSize: 14,
    color: "#718096"
  },
  statusContainer: {
    flexDirection: "row",
    alignItems: "center"
  },
  statusDot: {
    width: 8,
    height: 8,
    borderRadius: 4,
    marginRight: 6
  },
  statusText: {
    fontSize: 14,
    color: "#4A5568"
  },
  searchBarContainer: {
    marginBottom: 16
  },
  searchBar: {
    backgroundColor: "#F7FAFC",
    borderRadius: 8,
    padding: 12
  },
  invoiceNote: {
    fontSize: 13,
    color: "#718096",
    fontStyle: "italic",
    marginTop: 8
  },
  discountBadge: {
    fontSize: 11,
    color: "#805AD5",
    backgroundColor: "#E9D8FD",
    paddingHorizontal: 6,
    paddingVertical: 2,
    borderRadius: 4,
    marginBottom: 4,
    fontWeight: "500"
  },
  historyButton: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 12,
    backgroundColor: "#F7FAFC",
    borderRadius: 8,
    borderWidth: 1,
    borderColor: "#E2E8F0"
  },
  historyButtonText: {
    fontSize: 16,
    color: "#4A5568",
    fontWeight: "500"
  },
  emptyText: {
    fontSize: 16,
    color: "#718096",
    textAlign: "center",
    paddingVertical: 12
  },
  skeletonSummaryContainer: {
    marginBottom: 16,
    alignItems: "center",
    borderRadius: 8,
    backgroundColor: "#f5f5f5",
    marginHorizontal: 4
  },
  skeletonSearchBarContainer: {
    marginBottom: 16,
    marginHorizontal: 4
  },
  skeletonInvoiceItem: {
    borderWidth: 1,
    borderColor: "#E2E8F0",
    borderRadius: 8,
    padding: 16,
    marginBottom: 12
  }
});
