import React, { useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableOpacity,
  ActivityIndicator,
  Alert
} from "react-native";
import Glob from "src/globalConstants";
import NavBar from "src/components/navBar";
import { MaterialIcons } from "@expo/vector-icons";
import moment from "moment";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import Util from "src/utility";

const { width } = Glob.get("dimensions");

export default function PaymentsCustomerInvoiceDetails({ route, navigation }) {
  const {
    invoice,
    customerProfile,
    onPaymentSuccess,
    isPaidInvoice
  } = route.params;
  const [isProcessingPayment, setIsProcessingPayment] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(invoice.status);

  // Calculate discount information
  const hasDiscounts = invoice.discounts && invoice.discounts.length > 0;
  const subtotal = invoice.subtotal || 0;
  const total = isPaidInvoice ? invoice.amount_paid : invoice.amount_due;
  const discountAmount = subtotal > total ? subtotal - total : 0;

  // Format discount description
  const getDiscountDescription = (discount) => {
    if (discount.coupon) {
      if (discount.coupon.percent_off) {
        return `${discount.coupon.percent_off}% off`;
      }
      if (discount.coupon.amount_off) {
        return `$${(discount.coupon.amount_off / 100).toFixed(2)} off`;
      }
    }
    return "Discount applied";
  };

  const openInvoiceURL = (url) => {
    if (url) Util.openURL(url);
  };

  const handlePayInvoice = async () => {
    Analytics.logEvent("touch_payInvoiceWithDefaultMethod");
    setIsProcessingPayment(true);

    try {
      const response = await Database.stripeConnectedAccountPayInvoice({
        invoiceId: invoice.id
      });

      if (response.success) {
        // Call the callback to refresh the dashboard
        if (onPaymentSuccess) {
          onPaymentSuccess();
        }
        setPaymentStatus("paid");
        Alert.alert(
          "Payment Successful",
          "Your payment was processed successfully."
        );
      } else {
        Alert.alert(
          "Payment Failed",
          response.message || "There was an error processing your payment."
        );
      }
    } catch (error) {
      console.error("Error paying invoice:", error);
      Alert.alert(
        "Payment Error",
        "There was an error processing your payment. Please try again."
      );
    } finally {
      setIsProcessingPayment(false);
    }
  };

  // Check if customer has a default payment method
  const hasDefaultPaymentMethod = !!customerProfile?.invoice_settings
    ?.default_payment_method;
  // Determine if we should show the Pay Now button
  const isAutoPaymentInvoice = !invoice.due_date;
  const showPayNowButton =
    paymentStatus !== "paid" &&
    hasDefaultPaymentMethod &&
    !isAutoPaymentInvoice &&
    !isPaidInvoice;

  return (
    <View style={styles.pageContent}>
      <NavBar navigation={navigation} text="Invoice Details" />
      <ScrollView style={styles.scrollView}>
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Invoice Summary</Text>
          <View style={styles.detailRow}>
            <Text style={styles.label}>Status</Text>
            <Text
              style={[
                styles.value,
                {
                  color:
                    paymentStatus === "paid" || isPaidInvoice
                      ? "#2DD881"
                      : "#F6AD55"
                }
              ]}
            >
              {isPaidInvoice
                ? "Paid"
                : paymentStatus.charAt(0).toUpperCase() +
                  paymentStatus.slice(1)}
            </Text>
          </View>
          <View style={styles.detailRow}>
            <Text style={styles.label}>Amount</Text>
            <Text style={styles.value}>
              $
              {(
                (isPaidInvoice ? invoice.amount_paid : invoice.amount_due) / 100
              ).toFixed(2)}
            </Text>
          </View>
          <View style={styles.detailRow}>
            <Text style={styles.label}>Created</Text>
            <Text style={styles.value}>
              {moment(invoice.created * 1000).format("MMM D, YYYY")}
            </Text>
          </View>
          {isPaidInvoice && (
            <View style={styles.detailRow}>
              <Text style={styles.label}>Payment Date</Text>
              <Text style={styles.value}>
                {moment(invoice.status_transitions?.paid_at * 1000).format(
                  "MMM D, YYYY"
                )}
              </Text>
            </View>
          )}
          {!isPaidInvoice && invoice.due_date && (
            <View style={styles.detailRow}>
              <Text style={styles.label}>Due Date</Text>
              <Text style={styles.value}>
                {moment(invoice.due_date * 1000).format("MMM D, YYYY")}
              </Text>
            </View>
          )}
          {!isPaidInvoice && !invoice.due_date && (
            <View style={styles.detailRow}>
              <Text style={styles.label}>Payment</Text>
              <Text style={[styles.value, styles.autoPaymentText]}>
                Auto-payment
                {invoice.next_payment_attempt
                  ? ` on ${moment(invoice.next_payment_attempt * 1000).format(
                      "MMM D, YYYY"
                    )}`
                  : ""}
              </Text>
            </View>
          )}
          {isPaidInvoice && invoice.payment_intent && (
            <View style={styles.detailRow}>
              <Text style={styles.label}>Payment ID</Text>
              <Text style={styles.value}>
                {`${invoice.payment_intent.substring(0, 14)}...`}
              </Text>
            </View>
          )}
        </View>

        {/* New Discounts section - only show if discounts exist */}
        {hasDiscounts && (
          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Discounts Applied</Text>
            {invoice.discounts.map((discount, index) => (
              <View key={index} style={styles.discountItem}>
                <View style={styles.discountDetails}>
                  <Text style={styles.discountName}>
                    {discount.coupon?.name || "Discount"}
                  </Text>
                  <Text style={styles.discountDescription}>
                    {getDiscountDescription(discount)}
                  </Text>
                </View>
              </View>
            ))}
            {discountAmount > 0 && (
              <View style={styles.savingsSummary}>
                <Text style={styles.savingsText}>
                  You saved ${(discountAmount / 100).toFixed(2)} with applied
                  discounts
                </Text>
              </View>
            )}
          </View>
        )}

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Line Items</Text>
          {invoice.lines.data.map((item, index) => (
            <View key={index} style={styles.lineItem}>
              <Text style={styles.itemDescription}>{item.description}</Text>
              <Text style={styles.itemAmount}>
                ${(item.amount / 100).toFixed(2)}
              </Text>
            </View>
          ))}
        </View>

        <View style={styles.actions}>
          {showPayNowButton && (
            <TouchableOpacity
              style={[
                styles.payNowButton,
                isProcessingPayment && styles.disabledButton
              ]}
              onPress={handlePayInvoice}
              disabled={isProcessingPayment}
            >
              {isProcessingPayment ? (
                <ActivityIndicator size="small" color="white" />
              ) : (
                <>
                  <MaterialIcons name="payment" size={20} color="white" />
                  <Text style={styles.payNowButtonText}>Pay Now</Text>
                </>
              )}
            </TouchableOpacity>
          )}

          <TouchableOpacity
            style={styles.actionButton}
            onPress={() => openInvoiceURL(invoice.hosted_invoice_url)}
          >
            <MaterialIcons name="receipt" size={20} color="#4A5568" />
            <Text style={styles.actionButtonText}>View Invoice Online</Text>
          </TouchableOpacity>

          <TouchableOpacity
            style={styles.actionButton}
            onPress={() => openInvoiceURL(invoice.invoice_pdf)}
          >
            <MaterialIcons name="picture-as-pdf" size={20} color="#4A5568" />
            <Text style={styles.actionButtonText}>Download PDF</Text>
          </TouchableOpacity>
        </View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  pageContent: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "#F7FAFC"
  },
  scrollView: {
    flex: 1,
    padding: 16,
    width
  },
  section: {
    backgroundColor: "white",
    borderRadius: 8,
    padding: 16,
    marginBottom: 16
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: "600",
    color: "#2D3748",
    marginBottom: 16
  },
  detailRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 12
  },
  label: {
    color: "#718096",
    fontSize: 16
  },
  value: {
    color: "#2D3748",
    fontSize: 16,
    fontWeight: "500"
  },
  lineItem: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: 12,
    borderBottomWidth: 1,
    borderBottomColor: "#EDF2F7"
  },
  itemDescription: {
    flex: 1,
    color: "#2D3748",
    fontSize: 16
  },
  itemAmount: {
    color: "#2D3748",
    fontSize: 16,
    fontWeight: "500"
  },
  actions: {
    backgroundColor: "white",
    borderRadius: 8,
    padding: 16
  },
  actionButton: {
    flexDirection: "row",
    alignItems: "center",
    padding: 12,
    borderRadius: 6,
    backgroundColor: "#EDF2F7",
    marginBottom: 8
  },
  actionButtonText: {
    marginLeft: 8,
    color: "#4A5568",
    fontSize: 16,
    fontWeight: "500"
  },
  payNowButton: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: 12,
    borderRadius: 6,
    backgroundColor: "#2DD881",
    marginBottom: 16
  },
  payNowButtonText: {
    marginLeft: 8,
    color: "white",
    fontSize: 16,
    fontWeight: "500"
  },
  disabledButton: {
    opacity: 0.7
  },
  autoPaymentText: {
    color: "#4299E1"
  },
  discountItem: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: 12,
    borderBottomWidth: 1,
    borderBottomColor: "#EDF2F7"
  },
  discountDetails: {
    flex: 1
  },
  discountName: {
    fontSize: 16,
    fontWeight: "500",
    color: "#2D3748"
  },
  discountDescription: {
    fontSize: 14,
    color: "#805AD5",
    marginTop: 2
  },
  savingsSummary: {
    marginTop: 12,
    padding: 12,
    backgroundColor: "#F0FFF4",
    borderRadius: 6,
    borderLeftWidth: 3,
    borderLeftColor: "#2DD881"
  },
  savingsText: {
    fontSize: 14,
    color: "#276749"
  }
});
