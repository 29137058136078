import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  KeyboardAvoidingView,
  Platform,
  TouchableOpacity,
  ActivityIndicator,
  TextInput,
  Modal,
  Alert
} from "react-native";
import Glob from "src/globalConstants";
import Rex from "src/globalState";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import NavBar from "src/components/navBar";
import { MaterialIcons } from "@expo/vector-icons";
import Toast from "react-native-toast-message";
import Button from "src/components/Button";
import Dropdown from "src/components/Dropdown";
import moment from "moment";
import SearchBar from "src/components/searchBar";
import HelpText from "src/components/HelpText";

const { width } = Glob.get("dimensions");

// Utility function for formatting dates
const formatDate = (timestamp) => {
  if (!timestamp) return "N/A";
  return moment.unix(timestamp).format("MMM D, YYYY");
};

const DiscountItem = ({ discount, onDelete }) => {
  // Function to format the discount value
  const formatDiscountValue = () => {
    if (discount.percent_off !== null) {
      return `${discount.percent_off}% off`;
    }
    if (discount.amount_off !== null) {
      return `$${(discount.amount_off / 100).toFixed(2)} off`;
    }
    return "";
  };

  // Function to format the duration
  const formatDuration = () => {
    if (discount.duration === "once") {
      return "One-time use";
    }
    if (discount.duration === "repeating") {
      return `Repeats for ${discount.duration_in_months} months`;
    }
    if (discount.duration === "forever") {
      return "Never expires";
    }
    return "";
  };

  // Determine if discount is expired
  const isExpired =
    discount.redeem_by && discount.redeem_by < Math.floor(Date.now() / 1000);

  const discountValue = formatDiscountValue();

  return (
    <View
      style={[
        styles.discountItem,
        !discount.valid && styles.inactiveDiscountItem
      ]}
    >
      <View style={styles.discountHeader}>
        <View style={styles.discountTitleContainer}>
          <Text style={styles.discountName}>
            {discount.name || "Unnamed Discount"}
          </Text>
          <View style={styles.badgeContainer}>
            {!discount.valid && (
              <View style={[styles.badge, styles.inactiveBadge]}>
                <Text style={styles.badgeText}>Inactive</Text>
              </View>
            )}
            {discount.valid && isExpired && (
              <View style={[styles.badge, styles.expiredBadge]}>
                <Text style={styles.badgeText}>Expired</Text>
              </View>
            )}
          </View>
        </View>
        <TouchableOpacity
          onPress={() => onDelete(discount.id)}
          hitSlop={{ top: 10, right: 10, bottom: 10, left: 10 }}
          style={styles.deleteButton}
        >
          <MaterialIcons name="delete-outline" size={24} color="#E53E3E" />
        </TouchableOpacity>
      </View>

      {/* Discount Value (prominently displayed) */}
      <Text style={styles.discountValueText}>{discountValue}</Text>

      {/* Divider line */}
      <View style={styles.divider} />

      <View style={styles.discountDetailsContainer}>
        <View style={styles.discountDetail}>
          <Text style={styles.discountDetailLabel}>Duration</Text>
          <Text style={styles.discountDetailValue}>{formatDuration()}</Text>
        </View>
        {discount.redeem_by && (
          <View style={styles.discountDetail}>
            <Text style={styles.discountDetailLabel}>Expires</Text>
            <Text
              style={[
                styles.discountDetailValue,
                isExpired && styles.expiredText
              ]}
            >
              {formatDate(discount.redeem_by)}
            </Text>
          </View>
        )}
        {discount.max_redemptions && (
          <View style={styles.discountDetail}>
            <Text style={styles.discountDetailLabel}>Max Uses</Text>
            <Text style={styles.discountDetailValue}>
              {discount.max_redemptions}
            </Text>
          </View>
        )}
        {discount.times_redeemed > 0 && (
          <View style={styles.discountDetail}>
            <Text style={styles.discountDetailLabel}>Used</Text>
            <Text style={styles.discountDetailValue}>
              {discount.times_redeemed} time
              {discount.times_redeemed !== 1 ? "s" : ""}
            </Text>
          </View>
        )}
      </View>

      {discount.metadata?.description && (
        <View style={styles.discountDescription}>
          <Text style={styles.discountDescriptionLabel}>Description</Text>
          <Text style={styles.discountDescriptionText}>
            {discount.metadata.description}
          </Text>
        </View>
      )}

      {discount.promotion_codes &&
        discount.promotion_codes.data &&
        discount.promotion_codes.data.length > 0 && (
          <View style={styles.promoCodes}>
            <Text style={styles.promoCodesLabel}>Promo Codes:</Text>
            <View style={styles.promoCodesList}>
              {discount.promotion_codes.data.map((code) => (
                <View key={code.id} style={styles.promoCodeBadge}>
                  <Text style={styles.promoCodeText}>{code.code}</Text>
                </View>
              ))}
            </View>
          </View>
        )}
    </View>
  );
};

export default function CommerceDiscounts({ navigation }) {
  const [isLoading, setIsLoading] = useState(true);
  const [discounts, setDiscounts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredDiscounts, setFilteredDiscounts] = useState([]);
  const [showAddDiscountModal, setShowAddDiscountModal] = useState(false);

  // New discount form state
  const [newDiscount, setNewDiscount] = useState({
    name: "",
    type: "percent",
    value: "",
    duration: "once",
    duration_in_months: 3,
    currency: "usd",
    expires: null,
    max_redemptions: null,
    metadata: {
      description: ""
    }
  });

  // Form validation state
  const [formErrors, setFormErrors] = useState({});

  // Creation status
  const [isCreating, setIsCreating] = useState(false);

  // Create a ref to the organization's primary color
  const primaryColor = Rex.getConfig()?.colors?.navbar || "#6C63FF";

  useEffect(() => {
    Analytics.logEvent("view_commerceDiscounts");
    fetchDiscounts();
  }, []);

  const fetchDiscounts = async () => {
    setIsLoading(true);
    try {
      const response = await Database.stripeConnectedAccountFetchCoupons();
      if (response.success) {
        setDiscounts(response.coupons.data || []);
        setFilteredDiscounts(response.coupons.data || []);
      } else {
        Toast.show({
          type: "error",
          text1: "Error",
          text2: response.message || "Failed to fetch discounts"
        });
      }
    } catch (error) {
      console.error("Error fetching discounts:", error);
      Toast.show({
        type: "error",
        text1: "Error",
        text2: error.message || "Failed to fetch discounts"
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
    if (!query.trim()) {
      setFilteredDiscounts(discounts);
      return;
    }

    const filtered = discounts.filter((discount) => {
      const discountName = discount.name || "";
      const discountValue =
        discount.type === "percent"
          ? `${discount.percent_off}%`
          : `$${(discount.amount_off / 100).toFixed(2)}`;

      const searchString = `${discountName} ${discountValue}`.toLowerCase();
      return searchString.includes(query.toLowerCase());
    });
    setFilteredDiscounts(filtered);
  };

  const handleCreateDiscount = async () => {
    // Validate form
    const errors = {};
    if (!newDiscount.name.trim()) errors.name = "Name is required";
    if (
      !newDiscount.value ||
      Number.isNaN(Number(newDiscount.value)) ||
      Number(newDiscount.value) <= 0
    ) {
      errors.value = "Please enter a valid discount value";
    }

    if (newDiscount.type === "percent" && Number(newDiscount.value) > 100) {
      errors.value = "Percentage cannot exceed 100%";
    }

    if (
      newDiscount.duration === "repeating" &&
      (!newDiscount.duration_in_months ||
        Number.isNaN(Number(newDiscount.duration_in_months)) ||
        Number(newDiscount.duration_in_months) <= 0)
    ) {
      errors.duration_in_months = "Please enter a valid number of months";
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    setIsCreating(true);

    // Prepare coupon data for API
    const couponData = {
      name: newDiscount.name,
      type: newDiscount.type,
      value: Number(newDiscount.value),
      duration: newDiscount.duration,
      currency: newDiscount.currency
    };

    if (newDiscount.duration === "repeating") {
      couponData.duration_in_months = Number(newDiscount.duration_in_months);
    }

    if (newDiscount.expires) {
      couponData.expires = Math.floor(newDiscount.expires.getTime() / 1000);
    }

    if (newDiscount.max_redemptions) {
      couponData.max_redemptions = Number(newDiscount.max_redemptions);
    }

    if (newDiscount.metadata?.description) {
      couponData.metadata = {
        description: newDiscount.metadata.description
      };
    }

    try {
      const response = await Database.stripeConnectedAccountCreateCoupon(
        couponData
      );
      if (response.success) {
        Toast.show({
          type: "success",
          text1: "Success",
          text2: "Discount created successfully"
        });

        // Reset form and close modal
        setNewDiscount({
          name: "",
          type: "percent",
          value: "",
          duration: "once",
          duration_in_months: 3,
          currency: "usd",
          expires: null,
          max_redemptions: null,
          metadata: {
            description: ""
          }
        });
        setFormErrors({});
        setShowAddDiscountModal(false);

        // Refresh discounts
        fetchDiscounts();
      } else {
        Toast.show({
          type: "error",
          text1: "Error",
          text2: response.error || "Failed to create discount"
        });
      }
    } catch (error) {
      console.error("Error creating discount:", error);
      Toast.show({
        type: "error",
        text1: "Error",
        text2: error.message || "Failed to create discount"
      });
    } finally {
      setIsCreating(false);
    }
  };

  const handleDeleteDiscount = (discountId) => {
    Alert.alert(
      "Delete Discount",
      "Are you sure you want to delete this discount? This action cannot be undone.",
      [
        {
          text: "Cancel",
          style: "cancel"
        },
        {
          text: "Delete",
          style: "destructive",
          onPress: async () => {
            try {
              const response = await Database.stripeConnectedAccountDeleteCoupon(
                discountId
              );
              if (response.success) {
                Toast.show({
                  type: "success",
                  text1: "Success",
                  text2: "Discount deleted successfully"
                });

                // Refresh discounts
                fetchDiscounts();
              } else {
                Toast.show({
                  type: "error",
                  text1: "Error",
                  text2: response.error || "Failed to delete discount"
                });
              }
            } catch (error) {
              console.error("Error deleting discount:", error);
              Toast.show({
                type: "error",
                text1: "Error",
                text2: error.message || "Failed to delete discount"
              });
            }
          }
        }
      ]
    );
  };

  const renderAddDiscountModal = () => {
    return (
      <Modal
        visible={showAddDiscountModal}
        animationType="slide"
        transparent
        onRequestClose={() => setShowAddDiscountModal(false)}
      >
        <KeyboardAvoidingView
          behavior={Platform.OS === "ios" ? "padding" : "height"}
          style={styles.modalContainer}
        >
          <View style={styles.modalContent}>
            <View style={styles.modalHeader}>
              <Text style={styles.modalTitle}>Create New Discount</Text>
              <TouchableOpacity
                onPress={() => setShowAddDiscountModal(false)}
                hitSlop={{ top: 15, right: 15, bottom: 15, left: 15 }}
                style={styles.closeButton}
              >
                <MaterialIcons name="close" size={24} color="#4A5568" />
              </TouchableOpacity>
            </View>

            <ScrollView
              style={styles.modalBody}
              showsVerticalScrollIndicator={false}
              contentContainerStyle={styles.modalBodyContent}
            >
              <View style={styles.formGroup}>
                <Text style={styles.formLabel}>Discount Name</Text>
                <TextInput
                  style={[styles.input, formErrors.name && styles.inputError]}
                  placeholder="e.g., Summer Special, Family Discount"
                  value={newDiscount.name}
                  onChangeText={(text) => {
                    setNewDiscount({ ...newDiscount, name: text });
                    if (formErrors.name) {
                      const { name, ...rest } = formErrors;
                      setFormErrors(rest);
                    }
                  }}
                />
                {formErrors.name && (
                  <Text style={styles.errorText}>{formErrors.name}</Text>
                )}
              </View>

              <View style={styles.formGroup}>
                <Text style={styles.formLabel}>Discount Type</Text>
                <Dropdown
                  items={[
                    { text: "Percentage (%)", value: "percent" },
                    { text: "Fixed Amount ($)", value: "amount" }
                  ]}
                  value={newDiscount.type}
                  onSelect={(value) =>
                    setNewDiscount({ ...newDiscount, type: value })
                  }
                  placeholder="Select discount type"
                  style={styles.dropdown}
                />
              </View>

              <View style={styles.formGroup}>
                <Text style={styles.formLabel}>
                  {newDiscount.type === "percent"
                    ? "Percentage (%)"
                    : "Amount ($)"}
                </Text>
                <TextInput
                  style={[styles.input, formErrors.value && styles.inputError]}
                  placeholder={
                    newDiscount.type === "percent" ? "e.g., 10" : "e.g., 25.00"
                  }
                  keyboardType="decimal-pad"
                  value={newDiscount.value}
                  onChangeText={(text) => {
                    setNewDiscount({ ...newDiscount, value: text });
                    if (formErrors.value) {
                      const { value, ...rest } = formErrors;
                      setFormErrors(rest);
                    }
                  }}
                />
                {formErrors.value && (
                  <Text style={styles.errorText}>{formErrors.value}</Text>
                )}
              </View>

              <View style={styles.formGroup}>
                <Text style={styles.formLabel}>Duration</Text>
                <Dropdown
                  items={[
                    { text: "One-time", value: "once" },
                    { text: "Repeating", value: "repeating" },
                    { text: "Forever", value: "forever" }
                  ]}
                  value={newDiscount.duration}
                  onSelect={(value) =>
                    setNewDiscount({ ...newDiscount, duration: value })
                  }
                  placeholder="Select duration"
                  style={styles.dropdown}
                />
              </View>

              {newDiscount.duration === "repeating" && (
                <View style={styles.formGroup}>
                  <Text style={styles.formLabel}>Duration in Months</Text>
                  <TextInput
                    style={[
                      styles.input,
                      formErrors.duration_in_months && styles.inputError
                    ]}
                    placeholder="e.g., 3"
                    keyboardType="number-pad"
                    value={String(newDiscount.duration_in_months)}
                    onChangeText={(text) => {
                      setNewDiscount({
                        ...newDiscount,
                        duration_in_months: text
                      });
                      if (formErrors.duration_in_months) {
                        const { duration_in_months, ...rest } = formErrors;
                        setFormErrors(rest);
                      }
                    }}
                  />
                  {formErrors.duration_in_months && (
                    <Text style={styles.errorText}>
                      {formErrors.duration_in_months}
                    </Text>
                  )}
                </View>
              )}

              {/* todo: Implement these in the future at some point. They're commented out for now to reduce complexity. */}
              {/* <View style={styles.formGroup}>
                <View style={styles.switchRow}>
                  <Text style={styles.formLabel}>Set Expiration Date</Text>
                  <Switch
                    value={newDiscount.expires !== null}
                    onValueChange={(value) => {
                      setNewDiscount({
                        ...newDiscount,
                        expires: value
                          ? new Date(Date.now() + 30 * 24 * 60 * 60 * 1000)
                          : null
                      });
                    }}
                    trackColor={{ false: "#E2E8F0", true: primaryColor }}
                    thumbColor="#FFFFFF"
                  />
                </View>
                {newDiscount.expires && (
                  <View style={styles.datePickerContainer}>
                    <DatePicker
                      selectedDate={newDiscount.expires}
                      onDateChange={(date) => {
                        if (date) {
                          setNewDiscount({ ...newDiscount, expires: date });
                        }
                      }}
                      validRange={{ startDate: new Date() }}
                      style={styles.datePicker}
                    />
                  </View>
                )}
              </View> */}

              {/* <View style={styles.formGroup}>
                <View style={styles.switchRow}>
                  <Text style={styles.formLabel}>Limit Redemptions</Text>
                  <Switch
                    value={newDiscount.max_redemptions !== null}
                    onValueChange={(value) => {
                      setNewDiscount({
                        ...newDiscount,
                        max_redemptions: value ? 100 : null
                      });
                    }}
                    trackColor={{ false: "#E2E8F0", true: primaryColor }}
                    thumbColor="#FFFFFF"
                  />
                </View>
                {newDiscount.max_redemptions !== null && (
                  <TextInput
                    style={styles.input}
                    placeholder="e.g., 100"
                    keyboardType="number-pad"
                    value={String(newDiscount.max_redemptions)}
                    onChangeText={(text) => {
                      setNewDiscount({ ...newDiscount, max_redemptions: text });
                    }}
                  />
                )}
              </View> */}

              <View style={styles.formGroup}>
                <Text style={styles.formLabel}>Description (Optional)</Text>
                <TextInput
                  style={[styles.textArea]}
                  placeholder="Add details about this discount"
                  multiline
                  numberOfLines={4}
                  value={newDiscount.metadata.description}
                  onChangeText={(text) => {
                    setNewDiscount({
                      ...newDiscount,
                      metadata: {
                        ...newDiscount.metadata,
                        description: text
                      }
                    });
                  }}
                />
              </View>
            </ScrollView>

            <View style={styles.modalFooter}>
              <Button
                text="Cancel"
                onPress={() => setShowAddDiscountModal(false)}
                style={styles.cancelButton}
                textStyle={styles.cancelButtonText}
              />
              <Button
                text={isCreating ? "Creating..." : "Create Discount"}
                onPress={handleCreateDiscount}
                style={[
                  styles.createButton,
                  isCreating && styles.disabledButton
                ]}
                textStyle={styles.createButtonText}
                disabled={isCreating}
              />
            </View>
          </View>
        </KeyboardAvoidingView>
      </Modal>
    );
  };

  return (
    <>
      <KeyboardAvoidingView
        style={styles.container}
        behavior={Platform.OS === "ios" ? "padding" : undefined}
        keyboardVerticalOffset={Platform.OS === "ios" ? 64 : 0}
      >
        <NavBar navigation={navigation} text="Discount Management" />

        {isLoading ? (
          <View style={styles.loadingContainer}>
            <ActivityIndicator size="large" color={primaryColor} />
          </View>
        ) : (
          <View style={styles.pageContent}>
            <View style={styles.header}>
              <SearchBar
                placeholder="Search discounts..."
                onChangeText={handleSearch}
                customWidth={width - 40}
              />
            </View>

            {filteredDiscounts.length === 0 ? (
              <View style={styles.emptyStateContainer}>
                <View style={styles.emptyStateContent}>
                  <MaterialIcons
                    name="loyalty"
                    size={64}
                    color={`${primaryColor}80`}
                    style={styles.emptyStateIcon}
                  />
                  <Text style={styles.emptyStateTitle}>No discounts found</Text>
                  <Text style={styles.emptyStateText}>
                    {searchQuery
                      ? "Try changing your search query"
                      : "Create your first discount to start offering special prices"}
                  </Text>
                  {!searchQuery && (
                    <TouchableOpacity
                      style={styles.emptyStateButton}
                      onPress={() => setShowAddDiscountModal(true)}
                    >
                      <MaterialIcons name="add" size={20} color="white" />
                      <Text style={styles.emptyStateButtonText}>
                        Create Discount
                      </Text>
                    </TouchableOpacity>
                  )}
                </View>
              </View>
            ) : (
              <ScrollView
                style={styles.discountsList}
                contentContainerStyle={styles.discountsListContent}
                showsVerticalScrollIndicator={false}
              >
                <HelpText
                  text="Note: Stripe doesn't allow editing discounts once they are created. To edit a discount, you must delete it and create a new one."
                  noteStyle
                  style={{
                    marginTop: 20,
                    marginHorizontal: 20
                  }}
                />
                {filteredDiscounts.map((discount) => (
                  <DiscountItem
                    key={discount.id}
                    discount={discount}
                    onDelete={handleDeleteDiscount}
                  />
                ))}
              </ScrollView>
            )}

            <TouchableOpacity
              style={styles.floatingActionButton}
              onPress={() => setShowAddDiscountModal(true)}
            >
              <MaterialIcons name="add" size={28} color="white" />
            </TouchableOpacity>
          </View>
        )}
      </KeyboardAvoidingView>

      {renderAddDiscountModal()}
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "#F8F9FC"
  },
  pageContent: {
    flex: 1,
    width,
    padding: 20,
    paddingTop: 0
  },
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center"
  },
  header: {
    justifyContent: "center",
    marginBottom: 10
  },
  floatingActionButton: {
    position: "absolute",
    bottom: 24,
    right: 24,
    width: 64,
    height: 64,
    borderRadius: 32,
    backgroundColor: "#2DD881",
    justifyContent: "center",
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 8,
    elevation: 10
  },
  discountsList: {
    flex: 1
  },
  discountsListContent: {
    paddingBottom: 90
  },
  discountItem: {
    backgroundColor: "#FFFFFF",
    borderRadius: 20,
    padding: 20,
    marginBottom: 16,
    borderWidth: 1,
    borderColor: "#EEF1F5",
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 3 },
    shadowOpacity: 0.08,
    shadowRadius: 6,
    elevation: 3
  },
  inactiveDiscountItem: {
    borderColor: "#EEF1F5",
    backgroundColor: "#F8F9FC",
    opacity: 0.8
  },
  discountHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginBottom: 12
  },
  discountTitleContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap"
  },
  discountName: {
    fontSize: 18,
    fontWeight: "700",
    color: "#333F52",
    marginRight: 10
  },
  discountValueText: {
    fontSize: 24,
    fontWeight: "700",
    color: "#2DD881",
    marginBottom: 10
  },
  deleteButton: {
    padding: 6,
    borderRadius: 12,
    backgroundColor: "rgba(229, 62, 62, 0.08)"
  },
  badgeContainer: {
    flexDirection: "row",
    flexWrap: "wrap"
  },
  badge: {
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderRadius: 12,
    marginRight: 6,
    marginTop: 6
  },
  inactiveBadge: {
    backgroundColor: "#A0AEC0"
  },
  expiredBadge: {
    backgroundColor: "#F56565"
  },
  badgeText: {
    color: "#FFFFFF",
    fontSize: 12,
    fontWeight: "600"
  },
  divider: {
    borderBottomWidth: 1,
    borderBottomColor: "#EEF1F5",
    marginBottom: 16
  },
  discountDetailsContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginBottom: 16,
    backgroundColor: "#F8FAFD",
    borderRadius: 16,
    padding: 16
  },
  discountDetail: {
    marginRight: 28,
    marginBottom: 8
  },
  discountDetailLabel: {
    fontSize: 13,
    color: "#718096",
    marginBottom: 4,
    fontWeight: "500"
  },
  discountDetailValue: {
    fontSize: 15,
    color: "#333F52",
    fontWeight: "600"
  },
  expiredText: {
    color: "#F56565"
  },
  discountDescription: {
    marginBottom: 16,
    paddingBottom: 12,
    borderBottomWidth: 1,
    borderBottomColor: "#EEF1F5"
  },
  discountDescriptionLabel: {
    fontSize: 13,
    color: "#718096",
    marginBottom: 4,
    fontWeight: "500"
  },
  discountDescriptionText: {
    fontSize: 14,
    color: "#333F52",
    lineHeight: 20
  },
  promoCodes: {
    marginBottom: 16,
    paddingBottom: 12,
    borderBottomWidth: 1,
    borderBottomColor: "#EEF1F5"
  },
  promoCodesLabel: {
    fontSize: 13,
    color: "#718096",
    marginBottom: 8,
    fontWeight: "500"
  },
  promoCodesList: {
    flexDirection: "row",
    flexWrap: "wrap"
  },
  promoCodeBadge: {
    backgroundColor: "#EBF4FF",
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderRadius: 8,
    marginRight: 8,
    marginBottom: 6
  },
  promoCodeText: {
    color: "#3182CE",
    fontSize: 13,
    fontWeight: "600"
  },
  discountNote: {
    backgroundColor: "#FFF8E6",
    borderRadius: 12,
    padding: 12,
    borderLeftWidth: 4,
    borderLeftColor: "#F6B93B"
  },
  discountNoteText: {
    fontSize: 13,
    color: "#805B10",
    lineHeight: 18
  },

  // Modal styles
  modalContainer: {
    flex: 1,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    justifyContent: "flex-end",
    alignItems: "center"
  },
  modalContent: {
    backgroundColor: "#FFFFFF",
    borderTopLeftRadius: 28,
    borderTopRightRadius: 28,
    maxHeight: "90%",
    width,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: -4 },
    shadowOpacity: 0.12,
    shadowRadius: 10,
    elevation: 8
  },
  modalHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 20,
    borderBottomWidth: 1,
    borderBottomColor: "#EEF1F5"
  },
  closeButton: {
    padding: 6,
    borderRadius: 12,
    backgroundColor: "#F5F7FA"
  },
  modalTitle: {
    fontSize: 20,
    fontWeight: "700",
    color: "#333F52"
  },
  modalBody: {
    maxHeight: "70%"
  },
  modalBodyContent: {
    padding: 20,
    paddingBottom: 32
  },
  modalFooter: {
    padding: 20,
    paddingBottom: 34,
    borderTopWidth: 1,
    borderTopColor: "#EEF1F5",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  formGroup: {
    marginBottom: 22
  },
  formLabel: {
    fontSize: 16,
    fontWeight: "600",
    color: "#333F52",
    marginBottom: 10
  },
  input: {
    backgroundColor: "#FFFFFF",
    borderWidth: 1,
    borderColor: "#E6EAF0",
    borderRadius: 16,
    paddingHorizontal: 16,
    paddingVertical: 14,
    fontSize: 16,
    color: "#333F52"
  },
  inputError: {
    borderColor: "#F56565"
  },
  errorText: {
    color: "#F56565",
    fontSize: 14,
    marginTop: 6,
    fontWeight: "500"
  },
  dropdown: {
    backgroundColor: "#FFFFFF",
    borderWidth: 1,
    borderColor: "#E6EAF0",
    borderRadius: 16
  },
  textArea: {
    backgroundColor: "#FFFFFF",
    borderWidth: 1,
    borderColor: "#E6EAF0",
    borderRadius: 16,
    paddingHorizontal: 16,
    paddingVertical: 14,
    fontSize: 16,
    color: "#333F52",
    textAlignVertical: "top",
    minHeight: 120
  },
  switchRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 14
  },
  datePickerContainer: {
    backgroundColor: "#FFFFFF",
    borderWidth: 1,
    borderColor: "#E6EAF0",
    borderRadius: 16,
    padding: 10
  },
  datePicker: {
    marginBottom: 8
  },
  cancelButton: {
    flex: 1,
    backgroundColor: "#FFFFFF",
    borderWidth: 1,
    borderColor: "#E6EAF0",
    borderRadius: 16,
    marginRight: 10,
    height: 52,
    justifyContent: "center"
  },
  cancelButtonText: {
    color: "#384355",
    fontWeight: "600"
  },
  createButton: {
    flex: 1,
    backgroundColor: "#2DD881",
    borderRadius: 16,
    height: 52,
    justifyContent: "center"
  },
  createButtonText: {
    color: "#FFFFFF",
    fontWeight: "700"
  },
  disabledButton: {
    opacity: 0.6
  },

  // Empty state styles
  emptyStateContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: 20
  },
  emptyStateContent: {
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    padding: 32,
    borderRadius: 24,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.08,
    shadowRadius: 12,
    elevation: 5,
    width: "100%",
    maxWidth: 400
  },
  emptyStateIcon: {
    marginBottom: 20
  },
  emptyStateTitle: {
    fontSize: 22,
    fontWeight: "700",
    color: "#333F52",
    marginBottom: 10,
    textAlign: "center"
  },
  emptyStateText: {
    fontSize: 16,
    color: "#718096",
    textAlign: "center",
    marginBottom: 28,
    lineHeight: 22
  },
  emptyStateButton: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#2DD881",
    paddingHorizontal: 22,
    paddingVertical: 14,
    borderRadius: 16
  },
  emptyStateButtonText: {
    color: "white",
    fontSize: 16,
    fontWeight: "700",
    marginLeft: 8
  }
});
