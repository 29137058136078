import React, { useState } from "react";
import { Text, View, TouchableOpacity } from "react-native";
import { DatePickerModal, registerTranslation } from "react-native-paper-dates";
import Style from "src/globalStyles";
import Icon from "src/components/Icon";

// This only needs to be done once in the app
registerTranslation("en", {
  save: "Save",
  selectSingle: "Select a date",
  close: "Close",
  typeInDate: "Type in a date",
  pickDateFromCalendar: "Pick a date from the calendar",
  previous: "Previous",
  next: "Next",
  hours: "hours",
  minutes: "minutes"
});

export default function DatePicker({
  header,
  description,
  selectedDate,
  onDateChange,
  validRange,
  noHeaderMargin = false
}) {
  const [visible, setVisible] = useState(false);

  const onDismiss = () => {
    setVisible(false);
  };

  const onConfirm = ({ date }) => {
    onDateChange(date);
    setVisible(false);
  };

  let marginTop = 3;
  if (header) marginTop = noHeaderMargin ? 0 : 20;

  return (
    <View style={{ marginTop }}>
      <Text style={Style.get("headerText")}>{header}</Text>
      {!!description && <Text>{description}</Text>}
      <View style={{ flexDirection: "row", marginTop: 10 }}>
        <TouchableOpacity
          onPress={() => setVisible(true)}
          style={{
            flexDirection: "row",
            alignItems: "center",
            gap: 16,
            backgroundColor: "white",
            paddingHorizontal: 16,
            paddingVertical: 8,
            borderRadius: 8,
            borderWidth: 1,
            borderColor: "#e0e0e0"
          }}
        >
          <Text
            style={{
              fontSize: 16,
              color: "black"
            }}
          >
            {selectedDate
              ? selectedDate.toLocaleDateString("en-US", {
                  month: "short",
                  day: "numeric",
                  year: "numeric"
                })
              : "Select Date"}
          </Text>
          <Icon icon="calendar" size={20} color="gray" />
        </TouchableOpacity>
      </View>

      <DatePickerModal
        locale="en"
        mode="single"
        visible={visible}
        onDismiss={onDismiss}
        date={selectedDate}
        onConfirm={onConfirm}
        onChange={onConfirm}
        presentationStyle="pageSheet"
        label="Select a date"
        validRange={validRange}
      />
    </View>
  );
}
